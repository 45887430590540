import React, { Component, useState, useEffect } from "react"
import { div } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../../util';
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
} from "reactstrap"
import instance from '../../../config'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Roles = props => {
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [getCollapse, SetCollapse] = useState(false)
  const [getCollapse2, SetCollapse2] = useState(false)
  const [getCollapse3, SetCollapse3] = useState(false)
  const [dataRol, setDataRol] = useState([])
  const [getDataUsuario, setDataUsuario] = useState([])
  const [getUsuario, setUsuario] = useState("")
  const [, forceRender] = React.useState({});
  const abrirCollapse = (Collapse) => {
    if (Collapse == 1) {
      SetCollapse(!getCollapse)
    } else if (Collapse == 2) {
      SetCollapse2(!getCollapse2)
    } else if (Collapse == 3) {
      SetCollapse3(!getCollapse3)
    }
  }
  const FuncionListarAccesos = async (_nUsu_ID) => {
    var postData = {
      nUsu_ID: _nUsu_ID,
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].cUsu_Modulo == "ME01") {
              handleChange(1, 'hiddenDashboard', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME02") {
              handleChange(1, 'hiddenCaso', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME03") {
              handleChange(1, 'hiddenCliente', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME04") {
              handleChange(1, 'hiddenEvento', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME05") {
              handleChange(1, 'hiddenTarea', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME08") {
              handleChange(1, 'hiddenCalendario', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME27") {
              handleChange(1, 'hiddenCasosArchivados', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME28") {
              handleChange(1, 'hiddenLog', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME30") {
              handleChange(1, 'hiddenTarifas', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME31") {
              handleChange(1, 'hiddenCuentas', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME06") {
              handleChange(1, 'hiddenIngresos', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME07") {
              handleChange(1, 'hiddenEgresos', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME08") {
              handleChange(1, 'hiddenCalendario', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME09") {
              handleChange(1, 'hiddenDocumentos', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME10") {
              handleChange(1, 'hiddenHorasLaboradas', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME11") {
              handleChange(1, 'hiddenProcesarLiquidacion', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME25") {
              handleChange(1, 'hiddenFlujos', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME12") {
              handleChange(1, 'hiddenReportes', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME13") {
              handleChange(1, 'hiddenEstadoCuenta', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME14") {
              handleChange(1, 'hiddenLiquidacion', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME15") {
              handleChange(1, 'hiddenAnalitico', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME16") {
              handleChange(1, 'hiddenCorreosEnviados', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME17") {
              handleChange(1, 'hiddenPersonalizar', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME18") {
              handleChange(1, 'hiddenMiCuenta', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME19") {
              handleChange(1, 'hiddenRoles', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME20") {
              handleChange(1, 'hiddenUsuario', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME21") {
              handleChange(1, 'hiddenNotificacion', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME22") {
              handleChange(1, 'hiddenMaestros', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME23") {
              handleChange(1, 'hiddenCrearW', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            } else if (response.data[i].cUsu_Modulo == "ME24") {
              handleChange(1, 'hiddenSuscriptores', Boolean(parseInt(response.data[i].bUsu_Consultar)))
            }

            if (response.data[i].cUsu_Modulo == "ME01") {
              handleChange(2, 'hiddenDashboard', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME02") {
              handleChange(2, 'hiddenCaso', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME03") {
              handleChange(2, 'hiddenCliente', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME04") {
              handleChange(2, 'hiddenEvento', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME05") {
              handleChange(2, 'hiddenTarea', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME06") {
              handleChange(2, 'hiddenIngresos', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME07") {
              handleChange(2, 'hiddenEgresos', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME08") {
              handleChange(2, 'hiddenCalendario', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME09") {
              handleChange(2, 'hiddenDocumentos', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME10") {
              handleChange(2, 'hiddenHorasLaboradas', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME11") {
              handleChange(2, 'hiddenProcesarLiquidacion', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME12") {
              handleChange(2, 'hiddenReportes', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME13") {
              handleChange(2, 'hiddenEstadoCuenta', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME14") {
              handleChange(2, 'hiddenLiquidacion', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME15") {
              handleChange(2, 'hiddenAnalitico', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME16") {
              handleChange(2, 'hiddenCorreosEnviados', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME17") {
              handleChange(2, 'hiddenPersonalizar', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME18") {
              handleChange(2, 'hiddenMiCuenta', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME19") {
              handleChange(2, 'hiddenRoles', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME20") {
              handleChange(2, 'hiddenUsuario', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME21") {
              handleChange(2, 'hiddenNotificacion', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME22") {
              handleChange(2, 'hiddenMaestros', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME23") {
              handleChange(2, 'hiddenCrearW', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME24") {
              handleChange(2, 'hiddenSuscriptores', Boolean(parseInt(response.data[i].bUsu_Editar)))
            } else if (response.data[i].cUsu_Modulo == "ME25") {
              handleChange(2, 'hiddenFlujos', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME27") {
              handleChange(2, 'hiddenCasosArchivados', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME28") {
              handleChange(2, 'hiddenLog', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME30") {
              handleChange(2, 'hiddenTarifas', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME31") {
              handleChange(2, 'hiddenCuentas', Boolean(parseInt(response.data[i].bUsu_Editar)))
            }

            if (response.data[i].cUsu_Modulo == "ME01") {
              handleChange(3, 'hiddenDashboard', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME02") {
              handleChange(3, 'hiddenCaso', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME03") {
              handleChange(3, 'hiddenCliente', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME04") {
              handleChange(3, 'hiddenEvento', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME05") {
              handleChange(3, 'hiddenTarea', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME06") {
              handleChange(3, 'hiddenIngresos', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME07") {
              handleChange(3, 'hiddenEgresos', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME08") {
              handleChange(3, 'hiddenCalendario', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME09") {
              handleChange(3, 'hiddenDocumentos', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME10") {
              handleChange(3, 'hiddenHorasLaboradas', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME11") {
              handleChange(3, 'hiddenProcesarLiquidacion', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME12") {
              handleChange(3, 'hiddenReportes', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME13") {
              handleChange(3, 'hiddenEstadoCuenta', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME14") {
              handleChange(3, 'hiddenLiquidacion', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME15") {
              handleChange(3, 'hiddenAnalitico', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME16") {
              handleChange(3, 'hiddenCorreosEnviados', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME17") {
              handleChange(3, 'hiddenPersonalizar', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME18") {
              handleChange(3, 'hiddenMiCuenta', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME19") {
              handleChange(3, 'hiddenRoles', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME20") {
              handleChange(3, 'hiddenUsuario', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME21") {
              handleChange(3, 'hiddenNotificacion', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME22") {
              handleChange(3, 'hiddenMaestros', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME23") {
              handleChange(3, 'hiddenCrearW', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME24") {
              handleChange(3, 'hiddenSuscriptores', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            } else if (response.data[i].cUsu_Modulo == "ME25") {
              handleChange(3, 'hiddenFlujos', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME27") {
              handleChange(3, 'hiddenCasosArchivados', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME28") {
              handleChange(3, 'hiddenLog', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME30") {
              handleChange(3, 'hiddenTarifas', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
            else if (response.data[i].cUsu_Modulo == "ME31") {
              handleChange(3, 'hiddenCuentas', Boolean(parseInt(response.data[i].bUsu_Eliminar)))
            }
          }
        } else {
          setConsultar(prevState => ({
            ...prevState,
            hiddenDashboard: false,
            hiddenCaso: false,
            hiddenCliente: false,
            hiddenEvento: false,
            hiddenTarea: false,
            hiddenCalendario: false,
            hiddenCasosArchivados: false,
            hiddenLog: false,
            hiddenMiCuenta: false,
            hiddenTarifas: false,
            hiddenCuentas: false,
            hiddenIngresos: false,
            hiddenEgresos: false,
            hiddenDocumentos: false,
            hiddenHorasLaboradas: false,
            hiddenProcesarLiquidacion: false,
            hiddenReportes: false,
            hiddenEstadoCuenta: false,
            hiddenLiquidacion: false,
            hiddenAnalitico: false,
            hiddenCorreosEnviados: false,
            hiddenPersonalizar: false,
            hiddenRoles: false,
            hiddenUsuario: false,
            hiddenNotificacion: false,
            hiddenMaestros: false,
            hiddenCrearW: false,
            hiddenSuscriptores: false,
            hiddenFlujos: false,
          }))
          setEditar(prevState => ({
            ...prevState,
            hiddenDashboard: false,
            hiddenCaso: false,
            hiddenCliente: false,
            hiddenEvento: false,
            hiddenTarea: false,
            hiddenCalendario: false,
            hiddenCasosArchivados: false,
            hiddenLog: false,
            hiddenMiCuenta: false,
            hiddenTarifas: false,
            hiddenCuentas: false,
            hiddenIngresos: false,
            hiddenEgresos: false,
            hiddenDocumentos: false,
            hiddenHorasLaboradas: false,
            hiddenProcesarLiquidacion: false,
            hiddenReportes: false,
            hiddenEstadoCuenta: false,
            hiddenLiquidacion: false,
            hiddenAnalitico: false,
            hiddenCorreosEnviados: false,
            hiddenPersonalizar: false,
            hiddenRoles: false,
            hiddenUsuario: false,
            hiddenNotificacion: false,
            hiddenMaestros: false,
            hiddenCrearW: false,
            hiddenSuscriptores: false,
            hiddenFlujos: false,
          }))
          setEliminar(prevState => ({
            ...prevState,
            hiddenDashboard: false,
            hiddenCaso: false,
            hiddenCliente: false,
            hiddenEvento: false,
            hiddenTarea: false,
            hiddenCalendario: false,
            hiddenCasosArchivados: false,
            hiddenLog: false,
            hiddenMiCuenta: false,
            hiddenTarifas: false,
            hiddenCuentas: false,
            hiddenIngresos: false,
            hiddenEgresos: false,
            hiddenDocumentos: false,
            hiddenHorasLaboradas: false,
            hiddenProcesarLiquidacion: false,
            hiddenReportes: false,
            hiddenEstadoCuenta: false,
            hiddenLiquidacion: false,
            hiddenAnalitico: false,
            hiddenCorreosEnviados: false,
            hiddenPersonalizar: false,
            hiddenRoles: false,
            hiddenUsuario: false,
            hiddenNotificacion: false,
            hiddenMaestros: false,
            hiddenCrearW: false,
            hiddenSuscriptores: false,
            hiddenFlujos: false,
          }))
        }


        Swal.close();
      })
      .catch(error => {

      })
  }
  const abrirCerrarModal = (Modal, id) => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        Swal.fire({
          html:
            '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
            'animation: 3s rotate linear infinite;' +
            '-webkit-transform-origin: 50% 50%;' +
            'transform-origin: 50% 50%;" /> ' +
            '<br/><br/> Cargando...',
          showConfirmButton: false,
          allowOutsideClick: false
        });
        FuncionListarAccesos(id)
        FuncionListarPerfil(id)
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      setModalActualizar(!modalActualizar)
    } else if (Modal == 3) {
      setModalFiltro(!modalFiltro)
    }
  }
  const FuncionListarUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataUsuario(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarPerfil = async (_nUsu_ID) => {
    var postData = {
      nUsu_ID: _nUsu_ID,
    }
    await instance
      .post("/Usuario/Perfil", postData)
      .then(response => {
        setUsuario(response.data[0])
      })
      .catch(error => {

      })
  }
  const FuncionGuardarAcceso = async () => {
    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    await FuncionAgregarUsuarioAcceso("ME01", GetConsultar.hiddenDashboard, GetEditar.hiddenDashboard, GetEliminar.hiddenDashboard, GetConsultar.hiddenDashboard)
    await FuncionAgregarUsuarioAcceso("ME02", GetConsultar.hiddenCaso, GetEditar.hiddenCaso, GetEliminar.hiddenCaso, GetConsultar.hiddenCaso)
    await FuncionAgregarUsuarioAcceso("ME03", GetConsultar.hiddenCliente, GetEditar.hiddenCliente, GetEliminar.hiddenCliente, GetConsultar.hiddenCliente)
    await FuncionAgregarUsuarioAcceso("ME04", GetConsultar.hiddenEvento, GetEditar.hiddenEvento, GetEliminar.hiddenEvento, GetConsultar.hiddenEvento)
    await FuncionAgregarUsuarioAcceso("ME05", GetConsultar.hiddenTarea, GetEditar.hiddenTarea, GetEliminar.hiddenTarea, GetConsultar.hiddenTarea)
    await FuncionAgregarUsuarioAcceso("ME06", GetConsultar.hiddenIngresos, GetEditar.hiddenIngresos, GetEliminar.hiddenIngresos, GetConsultar.hiddenIngresos)
    await FuncionAgregarUsuarioAcceso("ME07", GetConsultar.hiddenEgresos, GetEditar.hiddenEgresos, GetEliminar.hiddenEgresos, GetConsultar.hiddenEgresos)

    await FuncionAgregarUsuarioAcceso("ME09", GetConsultar.hiddenDocumentos, GetEditar.hiddenDocumentos, GetEliminar.hiddenDocumentos, GetConsultar.hiddenDocumentos)
    await FuncionAgregarUsuarioAcceso("ME10", GetConsultar.hiddenHorasLaboradas, GetEditar.hiddenHorasLaboradas, GetEliminar.hiddenHorasLaboradas, GetConsultar.hiddenHorasLaboradas)
    await FuncionAgregarUsuarioAcceso("ME11", GetConsultar.hiddenProcesarLiquidacion, GetEditar.hiddenProcesarLiquidacion, GetEliminar.hiddenProcesarLiquidacion, GetConsultar.hiddenProcesarLiquidacion)
    await FuncionAgregarUsuarioAcceso("ME12", GetConsultar.hiddenReportes, GetEditar.hiddenReportes, GetEliminar.hiddenReportes, GetConsultar.hiddenReportes)
    await FuncionAgregarUsuarioAcceso("ME13", GetConsultar.hiddenEstadoCuenta, GetEditar.hiddenEstadoCuenta, GetEliminar.hiddenEstadoCuenta, GetConsultar.hiddenEstadoCuenta)
    await FuncionAgregarUsuarioAcceso("ME14", GetConsultar.hiddenLiquidacion, GetEditar.hiddenLiquidacion, GetEliminar.hiddenLiquidacion, GetConsultar.hiddenLiquidacion)
    await FuncionAgregarUsuarioAcceso("ME15", GetConsultar.hiddenAnalitico, GetEditar.hiddenAnalitico, GetEliminar.hiddenAnalitico, GetConsultar.hiddenAnalitico)
    await FuncionAgregarUsuarioAcceso("ME16", GetConsultar.hiddenCorreosEnviados, GetEditar.hiddenCorreosEnviados, GetEliminar.hiddenCorreosEnviados, GetConsultar.hiddenCorreosEnviados)
    await FuncionAgregarUsuarioAcceso("ME17", true, GetEditar.hiddenPersonalizar, GetEliminar.hiddenPersonalizar, true)

    await FuncionAgregarUsuarioAcceso("ME19", GetConsultar.hiddenRoles, GetEditar.hiddenRoles, GetEliminar.hiddenRoles, GetConsultar.hiddenRoles)
    await FuncionAgregarUsuarioAcceso("ME20", GetConsultar.hiddenUsuario, GetEditar.hiddenUsuario, GetEliminar.hiddenUsuario, GetConsultar.hiddenUsuario)
    await FuncionAgregarUsuarioAcceso("ME21", GetConsultar.hiddenNotificacion, GetEditar.hiddenNotificacion, GetEliminar.hiddenNotificacion, GetConsultar.hiddenNotificacion)
    await FuncionAgregarUsuarioAcceso("ME22", GetConsultar.hiddenMaestros, GetEditar.hiddenMaestros, GetEliminar.hiddenMaestros, GetConsultar.hiddenMaestros)
    await FuncionAgregarUsuarioAcceso("ME23", GetConsultar.hiddenCrearW, GetEditar.hiddenCrearW, GetEliminar.hiddenCrearW, GetConsultar.hiddenCrearW)
    await FuncionAgregarUsuarioAcceso("ME24", GetConsultar.hiddenSuscriptores, GetEditar.hiddenSuscriptores, GetEliminar.hiddenRoles, GetConsultar.hiddenSuscriptores)
    await FuncionAgregarUsuarioAcceso("ME25", GetConsultar.hiddenFlujos, GetEditar.hiddenFlujos, GetEliminar.hiddenFlujos, GetConsultar.hiddenFlujos)
    await FuncionAgregarUsuarioAcceso("ME08", GetConsultar.hiddenCalendario, GetEditar.hiddenCalendario, GetEliminar.hiddenCalendario, GetConsultar.hiddenCalendario)
    await FuncionAgregarUsuarioAcceso("ME27", GetConsultar.hiddenCasosArchivados, GetEditar.hiddenCasosArchivados, GetEliminar.hiddenCasosArchivados, GetConsultar.hiddenCasosArchivados)
    await FuncionAgregarUsuarioAcceso("ME28", GetConsultar.hiddenLog, GetEditar.hiddenLog, GetEliminar.hiddenLog, GetConsultar.hiddenLog)
    await FuncionAgregarUsuarioAcceso("ME18", GetConsultar.hiddenMiCuenta, GetEditar.hiddenMiCuenta, GetEliminar.hiddenMiCuenta, GetConsultar.hiddenMiCuenta)
    await FuncionAgregarUsuarioAcceso("ME30", GetConsultar.hiddenTarifas, GetEditar.hiddenTarifas, GetEliminar.hiddenTarifas, GetConsultar.hiddenTarifas)
    await FuncionAgregarUsuarioAcceso("ME31", GetConsultar.hiddenCuentas, GetEditar.hiddenCuentas, GetEliminar.hiddenCuentas, GetConsultar.hiddenCuentas)

    setTimeout(() => {
      Swal.close();
      Swal.fire({
        title: "Mensaje",
        text: "Agregado exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      })
      //setModalInsertar(!modalInsertar)
    }, 1500);
  }
  const FuncionAgregarUsuarioAcceso = async (_cUsu_Modulo, _cUsu_Opcion, _bUsu_Editar, _bUsu_Eliminar, _bUsu_Consultar) => {
    var postData = {
      nUsu_Id: parseInt(getUsuario),
      cUsu_Modulo: _cUsu_Modulo,
      cUsu_Opcion: _cUsu_Opcion,
      bUsu_Editar: _bUsu_Editar,
      bUsu_Eliminar: _bUsu_Eliminar,
      bUsu_Consultar: _bUsu_Consultar,
    }
    await instance
      .post("/Acceso/xDefecto", postData)
      .then(response => {


      })
      .catch(error => {

      })
  }
  const datas = {
    columns: [
      {
        label: "Rol",
        field: "nRol_ID2",
        sort: "asc",
        width: 10,
      },
      {
        label: "Nombre",
        field: "cUsu_Nombres",
        sort: "asc",
        width: 300,
      },
      {
        label: "Correo",
        field: "cUsu_email",
        sort: "asc",
        width: 10,
      },
      {
        label: "Teléfono",
        field: "cUsu_Fono",
        sort: "asc",
        width: 10,
      },
      {
        label: "Login",
        field: "cUsu_Login",
        sort: "asc",
        width: 10,
      },
    ],
    rows: getDataUsuario,
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        width: 150,
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <Button
              color="danger"
              className="waves-effect waves-light"
              style={{ width: '100%' }}
              onClick={() => {
                abrirCerrarModal(1, row.nUsu_ID)
              }}
              data-dismiss="modal"
            >
              Editar
            </Button>
          </div>
        ),
      })),
    ],
  }
  const [GetAccesoAdm, SetAccesoAdm] = useState(false)
  const [GetConsultar, setConsultar] = useState({
    hiddenDashboard: false,
    hiddenCaso: false,
    hiddenCliente: false,
    hiddenEvento: false,
    hiddenTarea: false,
    hiddenCalendario: false,
    hiddenCasosArchivados: false,
    hiddenLog: false,
    hiddenMiCuenta: false,
    hiddenTarifas: false,
    hiddenCuentas: false,
    hiddenIngresos: false,
    hiddenEgresos: false,
    hiddenDocumentos: false,
    hiddenHorasLaboradas: false,
    hiddenProcesarLiquidacion: false,
    hiddenReportes: true,
    hiddenEstadoCuenta: false,
    hiddenLiquidacion: false,
    hiddenAnalitico: false,
    hiddenCorreosEnviados: false,
    hiddenPersonalizar: false,
    hiddenRoles: false,
    hiddenUsuario: false,
    hiddenNotificacion: false,
    hiddenMaestros: false,
    hiddenCrearW: false,
    hiddenSuscriptores: false,
    hiddenFlujos: false,
  })
  const [GetEditar, setEditar] = useState({
    hiddenDashboard: false,
    hiddenCaso: false,
    hiddenCliente: false,
    hiddenEvento: false,
    hiddenTarea: false,
    hiddenCalendario: false,
    hiddenCasosArchivados: false,
    hiddenLog: false,
    hiddenMiCuenta: false,
    hiddenTarifas: false,
    hiddenCuentas: false,
    hiddenIngresos: false,
    hiddenEgresos: false,
    hiddenDocumentos: false,
    hiddenHorasLaboradas: false,
    hiddenProcesarLiquidacion: false,
    hiddenReportes: false,
    hiddenEstadoCuenta: false,
    hiddenLiquidacion: false,
    hiddenAnalitico: false,
    hiddenCorreosEnviados: false,
    hiddenPersonalizar: false,
    hiddenRoles: false,
    hiddenUsuario: false,
    hiddenNotificacion: false,
    hiddenMaestros: false,
    hiddenCrearW: false,
    hiddenSuscriptores: false,
    hiddenFlujos: false,
  })
  const [GetEliminar, setEliminar] = useState({
    hiddenDashboard: false,
    hiddenCaso: false,
    hiddenCliente: false,
    hiddenEvento: false,
    hiddenTarea: false,
    hiddenCalendario: false,
    hiddenCasosArchivados: false,
    hiddenLog: false,
    hiddenMiCuenta: false,
    hiddenTarifas: false,
    hiddenCuentas: false,
    hiddenIngresos: false,
    hiddenEgresos: false,
    hiddenDocumentos: false,
    hiddenHorasLaboradas: false,
    hiddenProcesarLiquidacion: false,
    hiddenReportes: false,
    hiddenEstadoCuenta: false,
    hiddenLiquidacion: false,
    hiddenAnalitico: false,
    hiddenCorreosEnviados: false,
    hiddenPersonalizar: false,
    hiddenRoles: false,
    hiddenUsuario: false,
    hiddenNotificacion: false,
    hiddenMaestros: false,
    hiddenCrearW: false,
    hiddenSuscriptores: false,
    hiddenFlujos: false,
  })
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)

  const handleChange = (t, name, value) => {
    if (t == 1) {
      setConsultar(prevState => ({
        ...prevState,
        [name]: value,
      }))
    } else if (t == 2) {
      setEditar(prevState => ({
        ...prevState,
        [name]: value,
      }))
    } else if (t == 3) {
      setEliminar(prevState => ({
        ...prevState,
        [name]: value,
      }))
    } else {
      SetAccesoAdm(value)
      setConsultar(prevState => ({
        ...prevState,
        hiddenDashboard: value,
        hiddenCaso: value,
        hiddenCliente: value,
        hiddenEvento: value,
        hiddenTarea: value,
        hiddenCalendario: value,
        hiddenCasosArchivados: value,
        hiddenLog: value,
        hiddenMiCuenta: value,
        hiddenTarifas: value,
        hiddenCuentas: value,
        hiddenIngresos: value,
        hiddenEgresos: value,
        hiddenDocumentos: value,
        hiddenHorasLaboradas: value,
        hiddenProcesarLiquidacion: value,
        hiddenReportes: value,
        hiddenEstadoCuenta: value,
        hiddenLiquidacion: value,
        hiddenAnalitico: value,
        hiddenCorreosEnviados: value,
        hiddenPersonalizar: value,
        hiddenRoles: value,
        hiddenUsuario: value,
        hiddenNotificacion: value,
        hiddenMaestros: value,
        hiddenCrearW: value,
        hiddenSuscriptores: value,
        hiddenFlujos: value,
      }))
      setEditar(prevState => ({
        ...prevState,
        hiddenDashboard: value,
        hiddenCaso: value,
        hiddenCliente: value,
        hiddenEvento: value,
        hiddenTarea: value,
        hiddenCalendario: value,
        hiddenCasosArchivados: value,
        hiddenLog: value,
        hiddenMiCuenta: value,
        hiddenTarifas: value,
        hiddenCuentas: value,
        hiddenIngresos: value,
        hiddenEgresos: value,
        hiddenDocumentos: value,
        hiddenHorasLaboradas: value,
        hiddenProcesarLiquidacion: value,
        hiddenReportes: value,
        hiddenEstadoCuenta: value,
        hiddenLiquidacion: value,
        hiddenAnalitico: value,
        hiddenCorreosEnviados: value,
        hiddenPersonalizar: value,
        hiddenRoles: value,
        hiddenUsuario: value,
        hiddenNotificacion: value,
        hiddenMaestros: value,
        hiddenCrearW: value,
        hiddenSuscriptores: value,
        hiddenFlujos: value,
      }))
      setEliminar(prevState => ({
        ...prevState,
        hiddenDashboard: value,
        hiddenCaso: value,
        hiddenCliente: value,
        hiddenEvento: value,
        hiddenTarea: value,
        hiddenCalendario: value,
        hiddenCasosArchivados: value,
        hiddenLog: value,
        hiddenMiCuenta: value,
        hiddenTarifas: value,
        hiddenCuentas: value,
        hiddenIngresos: value,
        hiddenEgresos: value,
        hiddenDocumentos: value,
        hiddenHorasLaboradas: value,
        hiddenProcesarLiquidacion: value,
        hiddenReportes: value,
        hiddenEstadoCuenta: value,
        hiddenLiquidacion: value,
        hiddenAnalitico: value,
        hiddenCorreosEnviados: value,
        hiddenPersonalizar: value,
        hiddenRoles: value,
        hiddenUsuario: value,
        hiddenNotificacion: value,
        hiddenMaestros: value,
        hiddenCrearW: value,
        hiddenSuscriptores: value,
        hiddenFlujos: value,
      }))
    }
    forceRender({})
  }
  const handleChangeCombo = e => {
    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    setUsuario(e.target.value)
    console.log(e.target.value)
    FuncionListarAccesos(e.target.value)
  }
  const FuncionListarRoles = async () => {
    await instance
      .post("/Roles/Listar",)
      .then(response => {
        setDataRol(response.data)

      })
      .catch(error => {

      })
  }

  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME19");
        console.log(permiso)

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }


  useEffect(() => {
    FuncionListarPermisos();
    FuncionListarRoles()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title>Configuración de roles | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Configuración de roles" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    {/* <button
                      type="button"
                      onClick={() => abrirCerrarModal(1, parseInt(getDecryptedItem("ID")))}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      Asignar permisos
                    </button>{" "} */}

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                    <span style={{ color: "#aa1b0E" }}>Asignar permisos</span>{" "}
                  </CardTitle>
                  <div className="modal-body" style={{ zIndex: '9' }}>
                    <div className="row">
                      <div className="col-md-7">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nRol_ID"
                              onChange={handleChangeCombo}
                            >
                              <option value="0">
                                Seleccionar
                              </option>
                              {dataRol.map((row, i) => (
                                <option key={i} value={row.nRol_ID}>
                                  {row.cRol_Nombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Rol
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name="hiddenCaso"
                              value={GetAccesoAdm}
                              checked={GetAccesoAdm}
                              onClick={() => handleChange(4, "", !GetAccesoAdm)}
                              style={{
                                paddingBottom: "1px",
                                marginBottom: "0px",
                                marginTop: "6px",
                                right: "178px",
                                left: "0px",
                                marginleft: "15px"
                              }}
                            />
                            <h5
                              className="form-check-label"
                              style={{
                                paddingLeft: "45px",
                                paddingTop: "7px"
                              }}
                            >
                              Este usuario tiene permisos de Administrador
                            </h5>
                            <p style={{
                              paddingLeft: "45px",
                              paddingTop: "0px"
                            }}>Los administradores tienen pleno acceso a la administración de usuarios importacíon/exportacíon actualización de personalizaciones de cuentas</p>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-12">
                            <h4
                              className="form-check-label"
                              style={{
                                paddingLeft: "45px",
                                paddingTop: "7px"
                              }}
                            >
                              Acceso a los módulos
                            </h4>
                            <p style={{
                              paddingLeft: "45px",
                              paddingTop: "0px"
                            }}>Puede establecer a qué módulos puede acceder el usuario</p>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-12">
                            <ul className="list-unstyled categories-list">
                              <li>
                                <div className="custom-accordion">
                                  <div
                                    className="text-body fw-medium py-1 d-flex align-items-center"
                                    onClick={() => abrirCollapse(1)}
                                    style={{ padding: "4px 12px" }}
                                  >
                                    <h4
                                      className="form-check-label"
                                      style={{
                                        paddingLeft: "35px",
                                        paddingTop: "0px"
                                      }}
                                    >
                                      Menú Principal
                                    </h4>
                                    {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                                    <i
                                      className={
                                        getCollapse
                                          ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                          : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                      }
                                    />
                                  </div>
                                  <Collapse isOpen={getCollapse}>
                                    <div className="card border-0 shadow-none ps-2 mb-0">
                                      <ul className="list-unstyled mb-0" style={{
                                        paddingLeft: "45px",
                                        paddingTop: "5px"
                                      }}>
                                        <li>
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ padding: "4px 12px" }}
                                          >
                                            <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                            </div>
                                            <div className="row col-md-12">
                                              <div className="col-md-2"><label
                                                className="text-detalle"
                                              >
                                                Consultar
                                              </label></div>
                                              <div className="col-md-2" style={{ marginLeft: "10px" }}><label
                                                className="text-detalle"
                                              >
                                                Editar
                                              </label></div>
                                              <div className="col-md-2" style={{
                                                marginLeft: "0px",
                                                paddingLeft: "0px",
                                                right: "1px"
                                              }}><label
                                                className="text-detalle"
                                              >
                                                  Eliminar
                                                </label></div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ padding: "4px 12px" }}
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Dashboard</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCaso"
                                                  value={GetConsultar.hiddenDashboard}
                                                  checked={GetConsultar.hiddenDashboard}
                                                  onClick={() => handleChange(1, "hiddenDashboard", !GetConsultar.hiddenDashboard)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCaso"
                                                  value={GetEditar.hiddenDashboard}
                                                  checked={GetEditar.hiddenDashboard}
                                                  onClick={() => handleChange(2, "hiddenDashboard", !GetEditar.hiddenDashboard)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCaso"
                                                  value={GetEliminar.hiddenDashboard}
                                                  checked={GetEliminar.hiddenDashboard}
                                                  onClick={() => handleChange(3, "hiddenDashboard", !GetEliminar.hiddenDashboard)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ padding: "4px 12px" }}
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Casos</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCaso"
                                                  value={GetConsultar.hiddenCaso}
                                                  checked={GetConsultar.hiddenCaso}
                                                  onClick={() => handleChange(1, "hiddenCaso", !GetConsultar.hiddenCaso)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCaso"
                                                  value={GetEditar.hiddenCaso}
                                                  checked={GetEditar.hiddenCaso}
                                                  onClick={() => handleChange(2, "hiddenCaso", !GetEditar.hiddenCaso)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCaso"
                                                  value={GetEliminar.hiddenCaso}
                                                  checked={GetEliminar.hiddenCaso}
                                                  onClick={() => handleChange(3, "hiddenCaso", !GetEliminar.hiddenCaso)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Cliente</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCliente"
                                                  value={GetConsultar.hiddenCliente}
                                                  checked={GetConsultar.hiddenCliente}
                                                  onClick={() => handleChange(1, "hiddenCliente", !GetConsultar.hiddenCliente)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCliente"
                                                  value={GetEditar.hiddenCliente}
                                                  checked={GetEditar.hiddenCliente}
                                                  onClick={() => handleChange(2, "hiddenCliente", !GetEditar.hiddenCliente)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCliente"
                                                  value={GetEliminar.hiddenCliente}
                                                  checked={GetEliminar.hiddenCliente}
                                                  onClick={() => handleChange(3, "hiddenCliente", !GetEliminar.hiddenCliente)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Eventos</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenEvento"
                                                  value={GetConsultar.hiddenEvento}
                                                  checked={GetConsultar.hiddenEvento}
                                                  onClick={() => handleChange(1, "hiddenEvento", !GetConsultar.hiddenEvento)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenEvento"
                                                  value={GetEditar.hiddenEvento}
                                                  checked={GetEditar.hiddenEvento}
                                                  onClick={() => handleChange(2, "hiddenEvento", !GetEditar.hiddenEvento)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenEvento"
                                                  value={GetEliminar.hiddenEvento}
                                                  checked={GetEliminar.hiddenEvento}
                                                  onClick={() => handleChange(3, "hiddenEvento", !GetEliminar.hiddenEvento)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Tareas</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenTarea"
                                                  value={GetConsultar.hiddenTarea}
                                                  checked={GetConsultar.hiddenTarea}
                                                  onClick={() => handleChange(1, "hiddenTarea", !GetConsultar.hiddenTarea)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenTarea"
                                                  value={GetEditar.hiddenTarea}
                                                  checked={GetEditar.hiddenTarea}
                                                  onClick={() => handleChange(2, "hiddenTarea", !GetEditar.hiddenTarea)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenTarea"
                                                  value={GetEliminar.hiddenTarea}
                                                  checked={GetEliminar.hiddenTarea}
                                                  onClick={() => handleChange(3, "hiddenTarea", !GetEliminar.hiddenTarea)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Calendario</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCalendario"
                                                  value={GetConsultar.hiddenCalendario}
                                                  checked={GetConsultar.hiddenCalendario}
                                                  onClick={() => handleChange(1, "hiddenCalendario", !GetConsultar.hiddenCalendario)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCalendario"
                                                  value={GetEditar.hiddenCalendario}
                                                  checked={GetEditar.hiddenCalendario}
                                                  onClick={() => handleChange(2, "hiddenCalendario", !GetEditar.hiddenCalendario)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCalendario"
                                                  value={GetEliminar.hiddenCalendario}
                                                  checked={GetEliminar.hiddenCalendario}
                                                  onClick={() => handleChange(3, "hiddenCalendario", !GetEliminar.hiddenCalendario)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Ingresos(<b>*</b>)</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenIngresos"
                                                  value={GetConsultar.hiddenIngresos}
                                                  checked={GetConsultar.hiddenIngresos}
                                                  onClick={() => handleChange(1, "hiddenIngresos", !GetConsultar.hiddenIngresos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenIngresos"
                                                  value={GetEditar.hiddenIngresos}
                                                  checked={GetEditar.hiddenIngresos}
                                                  onClick={() => handleChange(2, "hiddenIngresos", !GetEditar.hiddenIngresos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenIngresos"
                                                  value={GetEliminar.hiddenIngresos}
                                                  checked={GetEliminar.hiddenIngresos}
                                                  onClick={() => handleChange(3, "hiddenIngresos", !GetEliminar.hiddenIngresos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Egresos(<b>*</b>)</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenEgresos"
                                                  value={GetConsultar.hiddenEgresos}
                                                  checked={GetConsultar.hiddenEgresos}
                                                  onClick={() => handleChange(1, "hiddenEgresos", !GetConsultar.hiddenEgresos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenEgresos"
                                                  value={GetEditar.hiddenEgresos}
                                                  checked={GetEditar.hiddenEgresos}
                                                  onClick={() => handleChange(2, "hiddenEgresos", !GetEditar.hiddenEgresos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenEgresos"
                                                  value={GetEliminar.hiddenEgresos}
                                                  checked={GetEliminar.hiddenEgresos}
                                                  onClick={() => handleChange(3, "hiddenEgresos", !GetEliminar.hiddenEgresos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Horas Laboradas</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenHorasLaboradas"
                                                  value={GetConsultar.hiddenHorasLaboradas}
                                                  checked={GetConsultar.hiddenHorasLaboradas}
                                                  onClick={() => handleChange(1, "hiddenHorasLaboradas", !GetConsultar.hiddenHorasLaboradas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenHorasLaboradas"
                                                  value={GetEditar.hiddenHorasLaboradas}
                                                  checked={GetEditar.hiddenHorasLaboradas}
                                                  onClick={() => handleChange(2, "hiddenHorasLaboradas", !GetEditar.hiddenHorasLaboradas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenHorasLaboradas"
                                                  value={GetEliminar.hiddenHorasLaboradas}
                                                  checked={GetEliminar.hiddenHorasLaboradas}
                                                  onClick={() => handleChange(3, "hiddenHorasLaboradas", !GetEliminar.hiddenHorasLaboradas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Documentos</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenDocumentos"
                                                  value={GetConsultar.hiddenDocumentos}
                                                  checked={GetConsultar.hiddenDocumentos}
                                                  onClick={() => handleChange(1, "hiddenDocumentos", !GetConsultar.hiddenDocumentos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenDocumentos"
                                                  value={GetEditar.hiddenDocumentos}
                                                  checked={GetEditar.hiddenDocumentos}
                                                  onClick={() => handleChange(2, "hiddenDocumentos", !GetEditar.hiddenDocumentos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenDocumentos"
                                                  value={GetEliminar.hiddenDocumentos}
                                                  checked={GetEliminar.hiddenDocumentos}
                                                  onClick={() => handleChange(3, "hiddenDocumentos", !GetEliminar.hiddenDocumentos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>

                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Liquidaciones(<b>*</b>)</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenProcesarLiquidacion"
                                                  value={GetConsultar.hiddenProcesarLiquidacion}
                                                  checked={GetConsultar.hiddenProcesarLiquidacion}
                                                  onClick={() => handleChange(1, "hiddenProcesarLiquidacion", !GetConsultar.hiddenProcesarLiquidacion)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenProcesarLiquidacion"
                                                  value={GetEditar.hiddenProcesarLiquidacion}
                                                  checked={GetEditar.hiddenProcesarLiquidacion}
                                                  onClick={() => handleChange(2, "hiddenProcesarLiquidacion", !GetEditar.hiddenProcesarLiquidacion)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenProcesarLiquidacion"
                                                  value={GetEliminar.hiddenProcesarLiquidacion}
                                                  checked={GetEliminar.hiddenProcesarLiquidacion}
                                                  onClick={() => handleChange(3, "hiddenProcesarLiquidacion", !GetEliminar.hiddenProcesarLiquidacion)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span >Flujos</span>
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenFlujos"
                                                  value={GetConsultar.hiddenFlujos}
                                                  checked={GetConsultar.hiddenFlujos}
                                                  onClick={() => handleChange(1, "hiddenFlujos", !GetConsultar.hiddenFlujos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenFlujos"
                                                  value={GetEditar.hiddenFlujos}
                                                  checked={GetEditar.hiddenFlujos}
                                                  onClick={() => handleChange(2, "hiddenFlujos", !GetEditar.hiddenFlujos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenFlujos"
                                                  value={GetEliminar.hiddenFlujos}
                                                  checked={GetEliminar.hiddenFlujos}
                                                  onClick={() => handleChange(3, "hiddenFlujos", !GetEliminar.hiddenFlujos)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Collapse>
                                </div>
                              </li>
                              <li>
                                <div className="custom-accordion">
                                  <div
                                    className="text-body fw-medium py-1 d-flex align-items-center"
                                    onClick={() => abrirCollapse(2)}
                                    style={{ padding: "4px 12px" }}
                                  >
                                    <h4
                                      className="form-check-label"
                                      style={{
                                        paddingLeft: "35px",
                                        paddingTop: "0px"
                                      }}
                                    >
                                      Reportes
                                    </h4>
                                    {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                                    <i
                                      className={
                                        getCollapse2
                                          ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                          : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                      }
                                    />
                                  </div>
                                  <Collapse isOpen={getCollapse2}>
                                    <div className="card border-0 shadow-none ps-2 mb-0">
                                      <ul className="list-unstyled mb-0" style={{
                                        paddingLeft: "45px",
                                        paddingTop: "5px"
                                      }}>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                              </div>
                                              <div className="col-md-2">
                                                <label
                                                  className="text-detalle"
                                                >
                                                  Consultar
                                                </label>
                                              </div>
                                              <div className="col-md-2" style={{ marginLeft: "10px" }}><label
                                                className="text-detalle"
                                              >
                                                Editar
                                              </label></div>
                                              <div className="col-md-2" style={{
                                                marginLeft: "0px",
                                                paddingLeft: "0px",
                                                right: "1px"
                                              }}><label
                                                className="text-detalle"
                                              >
                                                  Eliminar
                                                </label></div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Estado de cuenta</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenEstadoCuenta"
                                                  value={GetConsultar.hiddenEstadoCuenta}
                                                  checked={GetConsultar.hiddenEstadoCuenta}
                                                  onClick={() => handleChange(1, "hiddenEstadoCuenta", !GetConsultar.hiddenEstadoCuenta)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenEstadoCuenta"
                                                  value={GetEditar.hiddenEstadoCuenta}
                                                  checked={GetEditar.hiddenEstadoCuenta}
                                                  onClick={() => handleChange(2, "hiddenEstadoCuenta", !GetEditar.hiddenEstadoCuenta)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenEstadoCuenta"
                                                  value={GetEliminar.hiddenEstadoCuenta}
                                                  checked={GetEliminar.hiddenEstadoCuenta}
                                                  onClick={() => handleChange(3, "hiddenEstadoCuenta", !GetEliminar.hiddenEstadoCuenta)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Analítico</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenAnalitico"
                                                  value={GetConsultar.hiddenAnalitico}
                                                  checked={GetConsultar.hiddenAnalitico}
                                                  onClick={() => handleChange(1, "hiddenAnalitico", !GetConsultar.hiddenAnalitico)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenAnalitico"
                                                  value={GetEditar.hiddenAnalitico}
                                                  checked={GetEditar.hiddenAnalitico}
                                                  onClick={() => handleChange(2, "hiddenAnalitico", !GetEditar.hiddenAnalitico)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenAnalitico"
                                                  value={GetEliminar.hiddenAnalitico}
                                                  checked={GetEliminar.hiddenAnalitico}
                                                  onClick={() => handleChange(3, "hiddenAnalitico", !GetEliminar.hiddenAnalitico)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Correos Enviados</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCorreosEnviados"
                                                  value={GetConsultar.hiddenCorreosEnviados}
                                                  checked={GetConsultar.hiddenCorreosEnviados}
                                                  onClick={() => handleChange(1, "hiddenCorreosEnviados", !GetConsultar.hiddenCorreosEnviados)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCorreosEnviados"
                                                  value={GetEditar.hiddenCorreosEnviados}
                                                  checked={GetEditar.hiddenCorreosEnviados}
                                                  onClick={() => handleChange(2, "hiddenCorreosEnviados", !GetEditar.hiddenCorreosEnviados)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCorreosEnviados"
                                                  value={GetEliminar.hiddenCorreosEnviados}
                                                  checked={GetEliminar.hiddenCorreosEnviados}
                                                  onClick={() => handleChange(3, "hiddenCorreosEnviados", !GetEliminar.hiddenCorreosEnviados)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>

                                        {/* <li>
                                              <div
                                                style={{ padding: "4px 12px" }}
                                                className="d-flex align-items-center"
                                              >
                                                <div className="row col-md-12">
                                                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                    <span>Liquidación</span>{" "}
                                                  </div>
                                                  <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                    <input
                                                      type="checkbox"
                                                      className="checkbox"
                                                      name="hiddenLiquidacion"
                                                      value={GetConsultar.hiddenLiquidacion}
                                                      checked={GetConsultar.hiddenLiquidacion}
                                                      onClick={() => handleChange(1, "hiddenLiquidacion", !GetConsultar.hiddenLiquidacion)}
                                                      style={{
                                                        marginBottom: "0px",
                                                        marginTop: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                    <input
                                                      type="checkbox"
                                                      className="checkbox2"
                                                      name="hiddenLiquidacion"
                                                      value={GetEditar.hiddenLiquidacion}
                                                      checked={GetEditar.hiddenLiquidacion}
                                                      onClick={() => handleChange(2, "hiddenLiquidacion", !GetEditar.hiddenLiquidacion)}
                                                      style={{
                                                        marginBottom: "0px",
                                                        marginTop: "0px",
                                                        marginleft: "0px"
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                    <input
                                                      type="checkbox"
                                                      className="checkbox2"
                                                      name="hiddenLiquidacion"
                                                      value={GetEliminar.hiddenLiquidacion}
                                                      checked={GetEliminar.hiddenLiquidacion}
                                                      onClick={() => handleChange(1, "hiddenLiquidacion", !GetEliminar.hiddenLiquidacion)}
                                                      style={{
                                                        marginBottom: "0px",
                                                        marginTop: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </li> */}
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Casos Inactivos</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCasosArchivados"
                                                  value={GetConsultar.hiddenCasosArchivados}
                                                  checked={GetConsultar.hiddenCasosArchivados}
                                                  onClick={() => handleChange(1, "hiddenCasosArchivados", !GetConsultar.hiddenCasosArchivados)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCasosArchivados"
                                                  value={GetEditar.hiddenCasosArchivados}
                                                  checked={GetEditar.hiddenCasosArchivados}
                                                  onClick={() => handleChange(2, "hiddenCasosArchivados", !GetEditar.hiddenCasosArchivados)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCasosArchivados"
                                                  value={GetEliminar.hiddenCasosArchivados}
                                                  checked={GetEliminar.hiddenCasosArchivados}
                                                  onClick={() => handleChange(3, "hiddenCasosArchivados", !GetEliminar.hiddenCasosArchivados)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Log</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenLog"
                                                  value={GetConsultar.hiddenLog}
                                                  checked={GetConsultar.hiddenLog}
                                                  onClick={() => handleChange(1, "hiddenLog", !GetConsultar.hiddenLog)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenLog"
                                                  value={GetEditar.hiddenLog}
                                                  checked={GetEditar.hiddenLog}
                                                  onClick={() => handleChange(2, "hiddenLog", !GetEditar.hiddenLog)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenLog"
                                                  value={GetEliminar.hiddenLog}
                                                  checked={GetEliminar.hiddenLog}
                                                  onClick={() => handleChange(3, "hiddenLog", !GetEliminar.hiddenLog)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>

                                      </ul>
                                    </div>
                                  </Collapse>
                                </div>
                              </li>
                              <li>
                                <div className="custom-accordion">
                                  <div
                                    className="text-body fw-medium py-1 d-flex align-items-center"
                                    onClick={() => abrirCollapse(3)}
                                    style={{ padding: "4px 12px" }}
                                  >
                                    <h4
                                      className="form-check-label"
                                      style={{
                                        paddingLeft: "35px",
                                        paddingTop: "0px"
                                      }}
                                    >
                                      Personalizar
                                    </h4>
                                    {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                                    <i
                                      className={
                                        getCollapse3
                                          ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                          : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                      }
                                    />
                                  </div>
                                  <Collapse isOpen={getCollapse3}>
                                    <div className="card border-0 shadow-none ps-2 mb-0">
                                      <ul className="list-unstyled mb-0" style={{
                                        paddingLeft: "45px",
                                        paddingTop: "5px"
                                      }}>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                              </div>
                                              <div className="col-md-2"><label
                                                className="text-detalle"
                                              >
                                                Consultar
                                              </label></div>
                                              <div className="col-md-2" style={{ marginLeft: "10px" }}><label
                                                className="text-detalle"
                                              >
                                                Editar
                                              </label></div>
                                              <div className="col-md-2" style={{
                                                marginLeft: "0px",
                                                paddingLeft: "0px",
                                                right: "1px"
                                              }}><label
                                                className="text-detalle"
                                              >
                                                  Eliminar
                                                </label></div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Mi cuenta</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenMiCuenta"
                                                  value={GetConsultar.hiddenMiCuenta}
                                                  checked={GetConsultar.hiddenMiCuenta}
                                                  onClick={() => handleChange(1, "hiddenMiCuenta", !GetConsultar.hiddenMiCuenta)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenMiCuenta"
                                                  value={GetEditar.hiddenMiCuenta}
                                                  checked={GetEditar.hiddenMiCuenta}
                                                  onClick={() => handleChange(2, "hiddenMiCuenta", !GetEditar.hiddenMiCuenta)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenMiCuenta"
                                                  value={GetEliminar.hiddenMiCuenta}
                                                  checked={GetEliminar.hiddenMiCuenta}
                                                  onClick={() => handleChange(3, "hiddenMiCuenta", !GetEliminar.hiddenMiCuenta)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Roles</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenRoles"
                                                  value={GetConsultar.hiddenRoles}
                                                  checked={GetConsultar.hiddenRoles}
                                                  onClick={() => handleChange(1, "hiddenRoles", !GetConsultar.hiddenRoles)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenRoles"
                                                  value={GetEditar.hiddenRoles}
                                                  checked={GetEditar.hiddenRoles}
                                                  onClick={() => handleChange(2, "hiddenRoles", !GetEditar.hiddenRoles)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenRoles"
                                                  value={GetEliminar.hiddenRoles}
                                                  checked={GetEliminar.hiddenRoles}
                                                  onClick={() => handleChange(3, "hiddenRoles", !GetEliminar.hiddenRoles)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span>Usuarios</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenUsuario"
                                                  value={GetConsultar.hiddenUsuario}
                                                  checked={GetConsultar.hiddenUsuario}
                                                  onClick={() => handleChange(1, "hiddenUsuario", !GetConsultar.hiddenUsuario)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenUsuario"
                                                  value={GetEditar.hiddenUsuario}
                                                  checked={GetEditar.hiddenUsuario}
                                                  onClick={() => handleChange(2, "hiddenUsuario", !GetEditar.hiddenUsuario)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenUsuario"
                                                  value={GetEliminar.hiddenUsuario}
                                                  checked={GetEliminar.hiddenUsuario}
                                                  onClick={() => handleChange(3, "hiddenUsuario", !GetEliminar.hiddenUsuario)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span className="Texto-Limit">Notificaciones</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenNotificacion"
                                                  value={GetConsultar.hiddenNotificacion}
                                                  checked={GetConsultar.hiddenNotificacion}
                                                  onClick={() => handleChange(1, "hiddenNotificacion", !GetConsultar.hiddenNotificacion)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenNotificacion"
                                                  value={GetEditar.hiddenNotificacion}
                                                  checked={GetEditar.hiddenNotificacion}
                                                  onClick={() => handleChange(2, "hiddenNotificacion", !GetEditar.hiddenNotificacion)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenNotificacion"
                                                  value={GetEliminar.hiddenNotificacion}
                                                  checked={GetEliminar.hiddenNotificacion}
                                                  onClick={() => handleChange(3, "hiddenNotificacion", !GetEliminar.hiddenNotificacion)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span className="Texto-Limit">Maestros</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenMaestros"
                                                  value={GetConsultar.hiddenMaestros}
                                                  checked={GetConsultar.hiddenMaestros}
                                                  onClick={() => handleChange(1, "hiddenMaestros", !GetConsultar.hiddenMaestros)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenMaestros"
                                                  value={GetEditar.hiddenMaestros}
                                                  checked={GetEditar.hiddenMaestros}
                                                  onClick={() => handleChange(2, "hiddenMaestros", !GetEditar.hiddenMaestros)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenMaestros"
                                                  value={GetEliminar.hiddenMaestros}
                                                  checked={GetEliminar.hiddenMaestros}
                                                  onClick={() => handleChange(3, "hiddenMaestros", !GetEliminar.hiddenMaestros)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span className="Texto-Limit">Tarifas</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenTarifas"
                                                  value={GetConsultar.hiddenTarifas}
                                                  checked={GetConsultar.hiddenTarifas}
                                                  onClick={() => handleChange(1, "hiddenTarifas", !GetConsultar.hiddenTarifas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenTarifas"
                                                  value={GetEditar.hiddenTarifas}
                                                  checked={GetEditar.hiddenTarifas}
                                                  onClick={() => handleChange(2, "hiddenTarifas", !GetEditar.hiddenTarifas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenTarifas"
                                                  value={GetEliminar.hiddenTarifas}
                                                  checked={GetEliminar.hiddenTarifas}
                                                  onClick={() => handleChange(3, "hiddenTarifas", !GetEliminar.hiddenTarifas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            style={{ padding: "4px 12px" }}
                                            className="d-flex align-items-center"
                                          >
                                            <div className="row col-md-12">
                                              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                                <span className="Texto-Limit">Cuentas</span>{" "}
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  name="hiddenCuentas"
                                                  value={GetConsultar.hiddenCuentas}
                                                  checked={GetConsultar.hiddenCuentas}
                                                  onClick={() => handleChange(1, "hiddenCuentas", !GetConsultar.hiddenCuentas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "8px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCuentas"
                                                  value={GetEditar.hiddenCuentas}
                                                  checked={GetEditar.hiddenCuentas}
                                                  onClick={() => handleChange(2, "hiddenCuentas", !GetEditar.hiddenCuentas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                    marginleft: "0px"
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox2"
                                                  name="hiddenCuentas"
                                                  value={GetEliminar.hiddenCuentas}
                                                  checked={GetEliminar.hiddenCuentas}
                                                  onClick={() => handleChange(3, "hiddenCuentas", !GetEliminar.hiddenCuentas)}
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Collapse>
                                  <br />
                                  (*)Restringe el acceso a todo la información del modulo incluido los casos asignados al usuario
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {/* <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button> */}
                          <button
                            hidden={hiddenBtnEditar}
                            type="button"

                            onClick={() => FuncionGuardarAcceso()}
                            className="btn btn-danger waves-effect waves-light"
                            style={{ marginLeft: "5px", width: '150px' }}
                          >
                            Guardar
                          </button>
                        </div>

                      </div>

                    </div>
                  </div>


                </CardBody>
              </Card>
            </Col>
            <Col className="col-2"></Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}
export default Roles
