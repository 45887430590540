import axios from 'axios';
import { getDecryptedItem, setEncryptedItem } from './util';  
const urlApi = process.env.REACT_APP_API_URL
const token =  getDecryptedItem('token');

const instance = axios.create({
  baseURL: urlApi
});

instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
export default instance;
