import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import instance from '../../config'
import { Link } from "react-router-dom"
// Reactstrap
import { Alert } from "reactstrap"
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logoDark from "../../assets/images/logo-light.png"
//i18n
import { withTranslation } from "react-i18next"
// Redux Store
import { toggleRightSidebar } from "../../store/actions"
import DocumentDropdown from "components/CommonForBoth/TopbarDropdown/DocumentDropdown"
import { getDecryptedItem } from '../../util';

const jsonwebtoken = require('jsonwebtoken');
const SECRET_KEY_JWT = "secretkey5353";

const baseUrl = process.env.REACT_APP_API_URL

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      nameBussines: {},
      logo: logoDark,
      modalFlujo: false,
      Day: 0,
      flagMsg: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleRightbar = this.toggleRightbar.bind(this)
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
  }

  toggleMenu() {
    this.props.toggleMenuCallback()
  }

  toggleRightbar() {
    this.props.toggleRightSidebar()
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement && !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  FuncionInfoSuscriptor = async () => {
    try {
      await instance
        .post("/Suscriptores/InfoSuscriptor")
        .then(response => {
          this.setState({ nameBussines: response.data[0] })
        })
    } catch (error) {

    }
  }
  FuncionGetLogo = async () => {
    try {
      let formdata = {
        FileName: 'logo.png',
      }
      await instance
        .post("/Usuario/DownloadFile", formdata)
        .then(response => {
          const Url = response.data.data
          console.log("Url", Url)
          if (Url !== null) {
            this.setState({ logo: Url })
          } else {
            this.FuncionInfoSuscriptor()
          }
        })
    } catch (error) {
      this.FuncionInfoSuscriptor()
    }
  }
  fnMenssageExpiration = async () => {
    try {
      const token = getDecryptedItem('token');
      const responseToken = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
      if (responseToken) {
        const { InfoDB } = responseToken.dataUser;
        const fechaActual = new Date();
        const fechaFin = new Date(InfoDB.dSsi_FechaVencimiento);
        const diferenciaEnMilisegundos = fechaFin - fechaActual;
        const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
        if (diferenciaEnDias < 4) {
          this.setState({ Day: diferenciaEnDias, flagMsg: true })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  componentDidMount() {
    this.FuncionGetLogo();
    // this.fnMenssageExpiration();
  }


  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="#" className="logo logo-dark">
                  <span className="logo-sm">
                    <i style={{ fontSize: "16px", color: "#fff" }} className="icon-icono-location" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="#" className="logo logo-light">
                  <span style={{ paddingTop: "25px" }} className="logo-sm">
                    <i style={{ fontSize: "45px", color: "#fff" }} className="icon-icono-location" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={logoDark}
                      alt=""
                      style={{ width: "200px", height: "auto", resizeMode: 'contain' }}
                    />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>


              <div className="d-flex">

                <h3 style={{
                  paddingLeft: "8px",
                  paddingTop: "18px"
                }}>{this.state.nameBussines.cSsi_Fac_Compania} </h3>
                <img
                  src={this.state.logo}
                  alt=""
                  style={{ width: "230px", height: "45px", marginTop: "16px" }}
                />
              </div>
              {/*  */}
              <div className="col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{
                  paddingLeft: "8px",
                  paddingTop: "10px"
                }}>
                  {this.state.flagMsg && (
                    <Alert color="warning">
                      Le quedan {this.state.Day} dia(s) antes del  vencimiento de la suscripción.
                    </Alert>
                  )}

                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={this.toggleFullscreen}
                  className="btn header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>
              <DocumentDropdown />
              <NotificationDropdown />
              <ProfileMenu />
            </div>
          </div>
        </header>

      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
}
