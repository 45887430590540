import React, { Component, useState, useEffect } from "react"
import ReactDOM from "react-dom";
import { MDBDataTable } from "mdbreact"
import { getDecryptedItem } from '../../util';
import {
  CardHeader,
  CardText,
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  Title,
  Text,
  FormGroup,
  FormText,
  Label,
  Input,
  CustomInput,
  CheckBox
} from "reactstrap"
import MetaTags from "react-meta-tags"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faSync,
  faChessPawn,
  faBalanceScale,
  faGlobe,
  faDownload,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Dependencia await instance para consumir apis
import instance from '../../config'
import Swal from "sweetalert2"
import { get } from "lodash";
import './style-documento.css';
import { array } from "prop-types";
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL;

var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10


const NuevoModulos123 = props => {
  // creo mis obj para que sean actualiazdos conforme se editen
  const [dataConsulta, setDataConsulta] = useState({
    inicio: '',
    fin: '',
    Case: 0,
    Client: 0
  })
  const [getDocumentos, setDocumentos] = useState({
    nCas_Id: 0,
    nCdo_Doc_Modo: 0,
    cCdo_Tab_Tipo_Doc: "",
    dCdo_Fecha_Solicito: "",
    cCdo_Archivo: "",
    dCdo_Fech_Entrega: ano + "-" + mes + "-" + dia,
    nCdo_Pendiente: 1,
    cCdo_NotaPendiente: "",
    nCdo_PorAprobar: 0,
    cCdo_NotaPorAprobar: "",
    nCdo_Aprobado: 0,
    dCdo_Fecha_Aprobo: "",
    cCdo_Documento: ""
  })

  console.log(getDocumentos);
  const [chkbox, setchkBox] = useState(true)

  const [isSearch, setIsSearch] = useState({
    isClient: false,
    isCase: true
  })
  const [dataEliminar, setDataEliminar] = useState([])
  const [fecha, setFecha] = useState('2021-01-01')
  var today = new Date();
  var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const [fechaCarga, setFechaCarga] = useState(date)
  const [dataPendiente, setDataPendiente] = useState([])
  const [dataPorAprobar, setDataPorAprobar] = useState([])
  const [dataAprobado, setDataAprobado] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [hiddenCliente, setHiddenCliente] = useState(false)

  /*
  
  ----------------------------
  */
  const [dataCases, setDataCases] = useState([])
  const [dataEliminado, setDataEliminado] = useState([])
  const [dataRechazados, setDataRechazados] = useState([])
  const [data, setData] = useState('2021-01-01')
  const [idDoc, setIdDoc] = useState({ id: 0 })
  const [dataCombo, setDataCombo] = useState([])
  const [getDisableCliente, setDisableCliente] = useState(false)
  const [fruites, setFruites] = useState([
    { id: 1, value: "banana", isChecked: false },
    { id: 2, value: "apple", isChecked: false },
    { id: 3, value: "mango", isChecked: false },
    { id: 4, value: "grap", isChecked: false }
  ])

  const [urlImg, setUrlImg] = useState('')
  const [dataCliente, setDataCliente] = useState([])
  const [dataDocumento, setDataDocumento] = useState([])
  //modal
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [modalEliminado, setModalEliminado] = useState(false)
  const [modalImg, setModalImg] = useState(false)
  const [modalInsertarBusqueda, setModalInsertarBusqueda] = useState(false)
  const [modalInsertarArchivo, setModalInsertarArchivo] = useState(false);
  const [modalInsertarComentario, setModalInsertarComentario] = useState(false);
  const [comentario, setComentario] = useState("");
  const [Disable, setDisable] = useState(true)
  const [dataSA, setDataSA] = useState({ status: 1, data: { nCdo_Id: 0 } });
  const [name, setName] = useState(false);
  const [xfile, xsetFile] = useState('');
  const send = event => {
  }
  const [getAdjunto, setAdjunto] = useState({
    cCdo_Archivo: "",
    nCdo_Id: 0
  });

  const LimpiarInputFile = async () => {

    if (xfile == '') {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar para limpiar la carga",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    } else {
      document.getElementById("file").value = ""
      xsetFile("")
    }
  }
  function encodeImageFileAsURL() {
    var filesSelected = document.getElementById("file").files
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0]
      var fileReader = new FileReader()
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result

        var newImage = document.createElement("iframe")
        newImage.src = srcData
        newImage.setAttribute("style", "width:100%;height:600px")
        // Create Base64 Object
        var array = srcData.split(",")

        var fileName = document.getElementById("file").value
        var idxDot = fileName.lastIndexOf(".") + 1
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
        if (extFile == "rar" || extFile == "zip") {
          const name = "<a>Este archivo no tiene vista previa</a>"
          document.getElementById("dase64_img").innerHTML = name
        } else {
          document.getElementById("dase64_img").innerHTML = newImage.outerHTML
        }
      }
      fileReader.readAsDataURL(fileToLoad)
    }
  }
  const validateFileType = async () => {
    var fileName = document.getElementById("file").value
    var idxDot = fileName.lastIndexOf(".") + 1
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "pdf" ||
      extFile == "rar" ||
      extFile == "zip"
    ) {
      var Valor = document.getElementById("cEge_Archivo").files[0].name
      setAdjunto(Valor);
    } else {
      setAdjunto("");
      Swal.fire({
        title: "Mensaje",
        text: "Formato no compatible",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  //modal
  const abrirCerrarModalArchivoInsertar = async (arr) => { /*******************www */
    //
    if (arr.status == 1) {
      setDataSA(arr)
      setModalInsertarArchivo(!modalInsertarArchivo)
    }

    /*else {
   setModalInsertarEmpresa(!modalInsertarEmpresa)
 }*/
  }
  const abrirCerrarModalImg = async (arr) => {
    //
    if (xfile == '') {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    } else {
      if (arr.status == 1) {
        setModalImg(!modalImg)
        encodeImageFileAsURL()
        //

      }
    }
  }
  const abrirCerrarModalEliminado = async (arr) => {

    let arr1 = []
    let ndata = dataEliminar.length;


    for (let i = 0; i <= ndata - 1; i++) {
      arr1.push(dataEliminar[i].nCdo_Id);
    }



    if (arr.status == 1) {
      setModalEliminado(!modalEliminado)
      fListarEliminado()


    } /*else {
      setModalInsertarEmpresa(!modalInsertarEmpresa)
    }*/
  }
  const abrirCerrarModalBusquedaInsertar = async (status) => {

    if (dataConsulta.Case > 0) {
      if (status == 1) {
        setModalInsertarBusqueda(!modalInsertarBusqueda)
        setDocumentos(prevState => ({
          ...prevState,
          nCas_Id: dataConsulta.Case,
        }))
      }
    } else {
      Swal.fire({
        title: "Mensaje",
        text: "Debe seleccionar un cliente y un caso",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const abrirCerrarModalInsertarComentario = async (d) => { //Insertar comentario
    setDataDocumento(d);
    setModalInsertarComentario(!modalInsertarComentario);
  }
  const FuncionAgregarDocumento = async () => {
    var today = new Date();
    let mes = today.getMonth() + 1;
    let dia = today.getDate();
    if (mes < 10) {
      mes = "0" + mes;
    }
    if (dia < 10) {
      dia = "0" + dia;
    }

    date = today.getFullYear() + '-' + mes + '-' + dia;

    setFecha(today.getFullYear() + '-01-01');


    var postData = {
      nCas_Id: parseInt(getDocumentos.nCas_Id),
      nCdo_Doc_Modo: parseInt(getDocumentos.nCdo_Doc_Modo),
      cCdo_Tab_Tipo_Doc: getDocumentos.cCdo_Tab_Tipo_Doc,
      dCdo_Fecha_Solicito: date,
      cCdo_Archivo: getDocumentos.cCdo_Archivo,
      dCdo_Fech_Entrega: getDocumentos.dCdo_Fech_Entrega,
      nCdo_Pendiente: 1,
      cCdo_NotaPendiente: "",
      nCdo_PorAprobar: 0,
      cCdo_NotaPorAprobar: "",
      nCdo_Aprobado: 0,
      dCdo_Fecha_Aprobo: fecha,
      cCdo_Documento: getDocumentos.cCdo_Documento,
      nCdo_Id: ""
    }

    console.log(postData);

    if (postData.dCdo_Fech_Entrega < postData.dCdo_Fecha_Solicito) {

      Swal.fire({
        title: "Mensaje",
        text: "La fecha de entrega no puede ser menor a la fecha de solicitud",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
    else {
      await instance
        .post("/Docs/Insert", postData)
        .then(async response => {
          //FuncionListarTareas()
          let nCdo_Id = response.data[0].nCdo_Id; // este es el id del evento creado
          postData.nCdo_Id = nCdo_Id;
          setModalInsertarBusqueda(!modalInsertarBusqueda)
          Swal.fire({
            title: "Mensaje",
            text: "Agregado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
          if (chkbox == true) {
            let objMails = {
              cliente: getDecryptedItem("NombreC"),
              dataDocumento: postData,
            }

            await instance
              .post("/mail/event/documentos", objMails)
              .then(res => {
                setchkBox(true);
              })
              .catch(error => {
                setchkBox(true);
              })
          }
          else if (chkbox == false) {
            setchkBox(true);
          }
          fListarPendiente(parseInt(dataConsulta.Case))
          fListarPorAprobar(parseInt(dataConsulta.Case))
          fListarAprobado(parseInt(dataConsulta.Case))
          fListarRechazados(parseInt(dataConsulta.Case))
          fListarEliminado(parseInt(dataConsulta.Case))
        })
        .catch(error => {
          Swal.fire({
            title: "Mensaje",
            text: "Algo salió mal",
            icon: "error",
            confirmButtonText: "Aceptar",
          })

        });

    }


  }
  const FuncionListarCasos = async () => {
    await instance
      .post("/Casos/ListarCbo")
      .then(response => {
        setDataCasoInfo(response.data)
      })
      .catch(error => {
      })
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {
      })
  }
  const fAprobarDocumento = async (data) => {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setFechaCarga(date)
    var postData = {
      nCdo_Id: parseInt(data.nCdo_Id),
      nCdo_Pendiente: 0,
      cCdo_NotaPendiente: "",
      nCdo_PorAprobar: 0,
      cCdo_NotaPorAprobar: "",
      nCdo_Aprobado: 1,
      dCdo_Fecha_Aprobo: fechaCarga,
    }
    await instance
      .post("/Docs/AprobarDoc", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Archivo aprobado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        fListarPendiente(parseInt(dataConsulta.Case))
        fListarPorAprobar(parseInt(dataConsulta.Case))
        fListarAprobado(parseInt(dataConsulta.Case))
        fListarRechazados(parseInt(dataConsulta.Case))
        fListarEliminado(parseInt(dataConsulta.Case))
      })
      .catch(error => {
        Swal.fire({
          title: "Mensaje",
          text: "Algo salió mal",
          icon: "error",
          confirmButtonText: "Aceptar",
        })

      })

  }
  let ArregloConsultaMasiva = []
  const ConsultasMasivas = async param1 => {
    let locationData = {
      nCdo_Id: param1,
    }
    var elem = document.getElementById("id" + param1)
    if (elem.checked) {
      ArregloConsultaMasiva.push(locationData)
    } else {
      var indice = ArregloConsultaMasiva.findIndex(
        nCdo_Id => nCdo_Id.nCdo_Id == param1
      )
      ArregloConsultaMasiva.splice(indice, 1)
    }
  }
  const FuncionActivarMasiva = async () => {
    //
    ArregloConsultaMasiva.map((row, id) => fEliminarDef(row.nCdo_Id))
    Swal.fire({
      title: "Mensaje",
      text: "Archivo eliminado correctamente",
      icon: "success",
      confirmButtonText: "Aceptar",
    })
  }
  const SeleccionarTodo = async () => {

    var elem = document.getElementById("selecionarMasivo")
    var i
    if (elem.checked) {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox")
          document.f1.elements[i].checked = true
    } else {
      ArregloConsultaMasiva = []
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox")
          document.f1.elements[i].checked = false
    }
  }

  const fEliminarDocumento = async (data) => {


    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setFechaCarga(date)


    var postData = {
      nCdo_Id: parseInt(data.nCdo_Id),

    }
    await instance
      .post("/Docs/EliminarDoc", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Archivo eliminado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        fListarPendiente(parseInt(dataConsulta.Case))
        fListarPorAprobar(parseInt(dataConsulta.Case))
        fListarAprobado(parseInt(dataConsulta.Case))
        fListarRechazados(parseInt(dataConsulta.Case))
        fListarEliminado(parseInt(dataConsulta.Case))
      })
      .catch(error => {
        Swal.fire({
          title: "Mensaje",
          text: "Algo salió mal",
          icon: "error",
          confirmButtonText: "Aceptar",
        })

      })

  }

  const fRevertirDocumento = async (data) => {


    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setFechaCarga(date)


    var postData = {
      nCdo_Id: parseInt(data.nCdo_Id),

    }
    await instance
      .post("/Docs/RevertirDoc", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Archivo recuperado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        fListarPendiente(parseInt(dataConsulta.Case))
        fListarPorAprobar(parseInt(dataConsulta.Case))
        fListarAprobado(parseInt(dataConsulta.Case))
        fListarRechazados(parseInt(dataConsulta.Case))
        fListarEliminado(parseInt(dataConsulta.Case))
      })
      .catch(error => {
        Swal.fire({
          title: "Mensaje",
          text: "Algo salió mal",
          icon: "error",
          confirmButtonText: "Aceptar",
        })

      })

  }
  const fEliminarDef = async (id) => {



    var postData = {
      nCdo_Id: parseInt(id),

    }
    await instance
      .post("/Docs/EliminarDef", postData)
      .then(response => {
        fListarPendiente(parseInt(dataConsulta.Case))
        fListarPorAprobar(parseInt(dataConsulta.Case))
        fListarAprobado(parseInt(dataConsulta.Case))
        fListarRechazados(parseInt(dataConsulta.Case))
        fListarEliminado(parseInt(dataConsulta.Case))
      })
      .catch(error => {
        Swal.fire({
          title: "Mensaje",
          text: "Algo salió mal",
          icon: "error",
          confirmButtonText: "Aceptar",
        })

      })

  }

  /** Realizar una validacion y devolver un emergente que indique que el archivo no fue encontrado */
  const VerDoc = async (data) => {
    var postData = {
      FileName: data.cCdo_Archivo,
    }
    await instance
      .post("/Documento/DownloadFile", postData)
      .then(response => {
        console.log("debug");
        console.log("response : ", response.data);

        let status = response.data.status;

        if(status == 500){
          Swal.fire({
            title: "Mensaje",
            text: "Archivo no encontrado, contacte con el administrador del sistema",
            icon: "error",
            confirmButtonText: "Aceptar",
          });


          

          return;
        }
  
        const Url = response.data.data
 
        console.log(Url);
        var win = window.open(Url, '_blank');
        win.focus();
      })
      .catch(error => {
      })
  }
  const fRechazarDocumento = async (data) => {

    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setFechaCarga(date)

    var postData = {
      nCdo_Id: parseInt(dataDocumento.nCdo_Id),
      nCdo_Pendiente: 0,
      cCdo_NotaPendiente: "",
      nCdo_PorAprobar: 0,
      cCdo_NotaPorAprobar: "",
      nCdo_Aprobado: 0,
      dCdo_Fecha_Aprobo: fecha,
      filename: dataDocumento.cCdo_Archivo
    }


    if (comentario == "" || comentario == null || comentario == undefined) {

      Swal.fire({
        title: "Mensaje",
        text: "Ingrese un comentario",
        icon: "error",
        confirmButtonText: "Aceptar",
      })

    }
    else {
      await instance
        .post("/Docs/RechazarDoc", postData)
        .then(async response => {
          let postData2 = {
            _nCdo_Id: parseInt(dataDocumento.nCdo_Id),
            _cCdo_comentario: comentario
          }
          await instance
            .post("/Docs/DocsInsertarComentario", postData2)
            .then(response => {
              Swal.fire({
                title: "Mensaje",
                text: "Archivo rechazado correctamente",
                icon: "success",
                confirmButtonText: "Aceptar",
              })
              fListarPendiente(parseInt(dataConsulta.Case))
              fListarPorAprobar(parseInt(dataConsulta.Case))
              fListarAprobado(parseInt(dataConsulta.Case))
              fListarRechazados(parseInt(dataConsulta.Case))
              fListarEliminado(parseInt(dataConsulta.Case))
            })
            .catch(error => {
              Swal.fire({
                title: "Mensaje",
                text: "Algo salió mal",
                icon: "error",
                confirmButtonText: "Aceptar",
              })

            })
        })
        .catch(error => {
          Swal.fire({
            title: "Mensaje",
            text: "Algo salió mal",
            icon: "error",
            confirmButtonText: "Aceptar",
          })

        })
      setComentario("");
    };

    abrirCerrarModalInsertarComentario(!modalInsertarComentario);
  }
  const fAdjuntarDocumento = async () => {


    var str = getAdjunto.cCdo_Archivo
    var array = str.split("\\", 3);

    setAdjunto({
      cCdo_Archivo: array[2]
    })

    if (getAdjunto.cCdo_Archivo == "") {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    } else {
      var array = str.split("\\", 3);
      var textDoc = array[2];
      var postData = {
        nCdo_Id: parseInt(dataSA.data.nCdo_Id),
        cCdo_Archivo: array[2],
        dCdo_Fech_Entrega: fechaCarga,
        nCdo_Pendiente: 0,
        cCdo_NotaPendiente: "",
        nCdo_PorAprobar: 1,
        cCdo_NotaPorAprobar: "",
        nCdo_Aprobado: 0,
        dCdo_Fecha_Aprobo: fecha,
      }
      await instance
        .post("/Docs/SubirDoc", postData)
        .then(response => {
          setModalInsertarArchivo(!modalInsertarArchivo)
          Swal.fire({
            title: "Mensaje",
            text: "Archivo cargado correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
          fListarPendiente(parseInt(dataConsulta.Case))
          fListarPorAprobar(parseInt(dataConsulta.Case))
          fListarAprobado(parseInt(dataConsulta.Case))
          fListarRechazados(parseInt(dataConsulta.Case))
          fListarEliminado(parseInt(dataConsulta.Case))
        })
        .catch(error => {
          Swal.fire({
            title: "Mensaje",
            text: "Algo salió mal",
            icon: "error",
            confirmButtonText: "Aceptar",
          })

        })
    }
  }
  const fListarComboDocumento = async () => {  // ==> Inentendible
    var postData = {
      Prefijo: "OD",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCombo(response.data)
      })
      .catch(error => {
      })
  }
  const fListarPendiente = async (id) => {
    let xdata = {
      client: dataConsulta.Client == '00' ? null : parseInt(dataConsulta.Client),
      case: parseInt(id)
    }
    await instance
      .post("/Docs/List", xdata)
      .then(response => {
        console.log(response.data);
        setDataPendiente(response.data)
        //
      })
      .catch(error => {
      })
  }
  const fListarEliminado = async (id) => {
    let xdata = {
      client: dataConsulta.Client == '00' ? null : parseInt(dataConsulta.Client),
      case: parseInt(id)
    }
    await instance
      .post("/Docs/ListEliminado", xdata)
      .then(response => {
        setDataEliminar(response.data);
        setDataEliminado(response.data)
        setList(response.data)
      })
      .catch(error => {
      })
  }
  const fListarAprobado = async (id) => {
    let xdata = {
      client: dataConsulta.Client == '00' ? null : parseInt(dataConsulta.Client),
      case: parseInt(id)
    }
    await instance
      .post("/Docs/ListAprobado", xdata)
      .then(response => {
        setDataAprobado(response.data)
        //
      })
      .catch(error => {
      })
  }
  const fListarPorAprobar = async (id) => {
    let xdata = {
      client: dataConsulta.Client == '00' ? null : parseInt(dataConsulta.Client),
      case: parseInt(id)
    }
    await instance
      .post("/Docs/ListPorAprobar", xdata)
      .then(response => {
        setDataPorAprobar(response.data)
        //
      })
      .catch(error => {
      })
  }
  const fListarRechazados = async (id) => {
    let xdata = {
      client: dataConsulta.Client == '00' ? null : parseInt(dataConsulta.Client),
      case: parseInt(id)
    }
    await instance
      .post("/Docs/ListRechazados", xdata)
      .then(response => {
        setDataRechazados(response.data)
        //
      })
      .catch(error => {
      })
  }
  const handleChangeDocumento = e => {

    const { name, value } = e.target
    setDocumentos(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == "checkbox") {
      setchkBox(!chkbox)
    }
  }
  const handleChangeAdjunto = e => {
    const { name, value } = e.target
    setAdjunto(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const postFile = async () => {
    console.log(xfile.size);
    if (xfile == '') {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    }
    else if (xfile.size > 5540000) {
      Swal.fire({
        title: "Mensaje",
        text: "El archivo supera el límite máximo de tamaño",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    } else {
      const formdata = new FormData()
      formdata.append("image", xfile)
      formdata.append("nCdo_Id", dataSA.data.nCdo_Id)

      await instance.post('/File/Upload', formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          setModalInsertarArchivo(!modalInsertarArchivo)
          fListarPendiente(parseInt(dataConsulta.Case))
          fListarPorAprobar(parseInt(dataConsulta.Case))
          fListarAprobado(parseInt(dataConsulta.Case))
          fListarRechazados(parseInt(dataConsulta.Case))
          fListarEliminado(parseInt(dataConsulta.Case))
          setUrlImg(response.data)
          Swal.fire({
            title: "Mensaje",
            text: "Archivo cargado correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          }).then(result => {
            if (result.isConfirmed) {
              localStorage.removeItem('CLIID');
              localStorage.removeItem('nCas_Id');
              window.location.reload();
            }
          })

        })
        .catch(error => {
        })

    }
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li.nCdo_Id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const thenSearch = () => {
    dataConsulta.Client == '00' ? listCasesByClient(dataConsulta.Client) : listCasesByClient(dataConsulta.Client)
  }
  const handleClick = e => {
    const { id, checked } = e.target;
    abrirCerrarModalBusquedaInsertar(1)
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };
  const catalog = list.map(({ nCdo_Id, cCdo_Documento }) => {
    return (
      <input
        key={nCdo_Id}
        type="checkbox"
        name={cCdo_Documento}
        id={nCdo_Id}
        onChange={handleClick}
        checked={isCheck.includes(nCdo_Id)}
      />

    );
  });
  const FuncionBuscarCasos = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
    }

    if (_nCas_Id == '0') {
      setDisable(true);
    }
    await instance
      .post("/Casos/Buscar", postData)
      .then(response => {
        setDataCasoInfo(response.data)
        let email = response.data[0]["cCli_Email1"];
        if (email == '' || email == undefined) {
          Swal.fire({
            title: "Mensaje",
            text: "No se puede solicitar documentos. Actualice el correo para poder solicitar documentos.",
            icon: "warning",
            confirmButtonText: "Aceptar",
          })
          setDisable(true);
        }
        else {
          setDisable(false);
        }
      })
      .catch(error => {
      })
  }
  const listCasesByClient = async (id) => {
    setDataConsulta({ ...dataConsulta, Client: id })
    var postData = {
      idClient: id,
      _id: parseInt(getDecryptedItem("ID"))
    }
    await instance
      .post("/Casos/findClient", postData)
      .then(res => {
        setDataCases(res.data)

      })
      .catch(err => {
      })
  }

  const handleChangeBusqueda = async (e) => {
    if (e.target.value == 0) {
      setDataConsulta({ ...dataConsulta, Case: 0, Client: 0 })
    } else {
      listCasesByClient(e.target.value)
    }
  }
  const handleChangeBusquedaCaso = async (e) => {
    setDataConsulta({ ...dataConsulta, Case: e.target.value })
    ListarMaestro(e.target.value)
  }
  const ListarMaestro = (id) => {
    fListarPendiente(id)
    fListarPorAprobar(id)
    fListarAprobado(id)
    fListarRechazados(id)
    fListarEliminado(id)
    FuncionBuscarCasos(id)
  }
  const handleChange = async (e) => {
    const { name, value } = e.target

    if (name == "cCdo_comentario") {
      setComentario(value)
    }

  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME09");
        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {
      })
  }
  const FuncionBloquearCombo = async () => {
    let rol = parseInt(getDecryptedItem("RolID"))
    if (rol == 9) {
      setDataConsulta({ ...dataConsulta, Client: parseInt(getDecryptedItem("CLIID")) })
      setDisableCliente(true)
      listCasesByClient(parseInt(getDecryptedItem("CLIID")))
    }
  }
  useEffect(() => {

    if (getDecryptedItem("RolID") == 9) {
      setHiddenCliente(true);
      let nomCli = getDecryptedItem("NombreC");
      setDataCasoInfo([{ cCli_NombreCompleto: nomCli }]);
    }
    FuncionListarPermisos();
    FuncionListarCliente()
    fListarComboDocumento()
    FuncionBloquearCombo()
    var CASOID = getDecryptedItem("nCas_Id");
    let CLIID = getDecryptedItem("CLIID");
    if (CASOID !== null && CASOID !== undefined && CASOID !== 0 && CLIID !== null && CLIID !== undefined && CLIID !== 0) {
      listCasesByClient(CLIID);
      FuncionBuscarCasos(CASOID)
      fListarPendiente(CASOID)
      fListarPorAprobar(CASOID)
      fListarAprobado(CASOID)
      fListarRechazados(CASOID)
      fListarEliminado(CASOID)
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title> Documentos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Documentos" />
          <Row>
            <Col className="col-12">
              <Modal size="col-12" isOpen={modalInsertarComentario}>
                <div className="modal-header ModalHeaderColor">
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    <span style={{ fontWeight: '600' }}> Motivo del rechazo</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalInsertarComentario()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-3 row">
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        placeholder="Ingrese el motivo"
                        name="cCdo_comentario"
                        onChange={handleChange}
                        maxLength="600"
                      ></textarea>
                      <label className="col-form-label labelDown">Motivo</label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalInsertarComentario()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => fRechazarDocumento()}
                  >
                    Guardar <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </Modal>
              <Modal size="lg" isOpen={modalImg}>
                <div
                  className="modal-header ModalHeaderColor"
                >
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    Vista Previa
                  </h5>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalImg({ status: 1, data: {} })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dase64_img"></div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalImg({ status: 1, data: {} })}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                </div>
              </Modal>
              <Modal size="lg" isOpen={modalEliminado}>
                <div
                  className="modal-header ModalHeaderColor"
                >
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    Papelera de <span style={{ fontWeight: '600' }}> reciclaje</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() =>

                      abrirCerrarModalEliminado({ status: 1, data: {} })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="item">
                    <form name="f1">
                      <div className="hijos3">
                        {dataEliminado.map(function (d, idx) {
                          return (<div style={{ padding: '20px', fontcolor: 'black', background: '#dddde8', borderRadius: '15px' }}>

                            <h5 style={{ fontWeight: '600' }}><i className="fas fa-file"></i>&nbsp;{d.cCdo_Documento} <input className="" style={{}} type="checkbox"

                              id={"id" + d.nCdo_Id}
                              onClick={() => ConsultasMasivas(d.nCdo_Id)}
                            ></input></h5>
                            <b>Archivo:</b><label> {d.cCdo_Archivo1}</label><br></br>
                            <b>Tipo de Documento:</b><label>{d.cCdo_Tab_Tipo_Doc}</label><br></br>
                            <b>Fecha/hora:</b><label> {d.dCdo_Fecha_Papelera}</label><br></br>
                            <Button color="danger"
                              onClick={() => fRevertirDocumento(d)}
                            ><i className="fas fa-undo-alt"></i>&nbsp;Recuperar</Button>
                            &nbsp;
                            <button type="button" className="btn btn-primary"
                              onClick={() => VerDoc(d)}>
                              <i className="fas fa-eye"></i>
                              &nbsp;
                              Visualizar

                            </button>


                          </div>
                          )
                        })}
                      </div>
                    </form>
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => ""}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                  >

                    <input
                      type="checkbox"
                      className="checkbox"
                      id="selecionarMasivo"
                      style={{ marginTop: "-3px", marginLeft: "-0.6em" }}
                      onClick={() => SeleccionarTodo()}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    Seleccionar todos
                  </button>
                  <button
                    type="button"
                    onClick={() => FuncionActivarMasiva()}
                    className="btn btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-trash"></i>&nbsp;
                    Eliminar Definitivamente

                  </button>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalEliminado({ status: 1, data: {} })}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >

                    Cerrar
                  </button>

                </div>
              </Modal>
              <Modal size="col-8" isOpen={modalInsertarArchivo}>
                <div
                  className="modal-header ModalHeaderColor"
                >
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    Subir <span style={{ fontWeight: '600' }}> documento</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() =>

                      abrirCerrarModalArchivoInsertar({ status: 1, data: {} })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                  <div className="">
                    <Label for="exampleFile">Adjuntar archivo</Label>
                    <br></br>
                    <input
                      className="form-control"
                      type="file"
                      placeholder="Usuario"
                      name="cEge_Archivo"
                      id="file"
                      onChange={(e) => {
                        const file = e.target.files[0]
                        //
                        xsetFile(file);
                        //postFile(file);
                        validateFileType
                      }}
                    />

                    <label className="col-form-label labelDown">
                      <b>Adjuntar máximo 5 MB (PDF, JPG, PNG)</b>
                    </label>
                  </div>
                  <br />
                  <div className="">
                    <button type="button" className="btn btn-info"
                      onClick={() => abrirCerrarModalImg({ status: 1, data: {} })}>
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          fontSize: "17px",
                          marginTop: "0",
                          cursor: "pointer",
                        }}
                        icon={faEye}
                        className=""
                      />
                      &nbsp;
                      Visualizar

                    </button>
                    &nbsp;
                    &nbsp;
                    <button type="button" className="btn btn-dark"
                      onClick={() => LimpiarInputFile()
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          fontSize: "17px",
                          marginTop: "0",
                          cursor: "pointer",
                        }}
                        className=""
                        icon={faTrash}
                      />&nbsp;
                      Eliminar

                    </button>


                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalArchivoInsertar({ status: 1, data: {} })}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      postFile()
                    }}
                  >
                    Guardar <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </Modal>
              <Modal size="col-12" isOpen={modalInsertarBusqueda}>
                <div
                  className="modal-header ModalHeaderColor"
                  hidden={hiddenBtnEditar}
                >
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    Solicitar <span style={{ fontWeight: '600' }}> documento</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalBusquedaInsertar(1)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-3 row">
                    <div className="col-12 text-center">
                      <label style={{ textAlign: 'left', marginLeft: '-5em' }}
                        htmlFor="example-email-input"
                        className="col-md-6 col-form-label"
                      >
                        Tipo de documento
                      </label>
                      <select style={{ width: '300px' }}
                        className="col-2 form-select inputDown"
                        name="cCdo_Tab_Tipo_Doc"
                        onChange={handleChangeDocumento}
                      >
                        <option value="00">Seleccionar</option>
                        {dataCombo.map((e) =>
                          <option value={e.cTabCodigo}>
                            {e.cTabNombre}
                          </option>
                        )
                        }
                      </select>
                    </div>

                    <div className="col-12 text-center">
                      <label
                        style={{ textAlign: 'left', marginLeft: '6.4em' }}
                        htmlFor="example-email-input"
                        className="col-md-12 col-form-label"
                      >
                        Documento
                      </label>
                      <input style={{ width: '300px', marginLeft: '6.4em' }}
                        placeholder="Documento"
                        className="col-2  form-control"
                        type="email"
                        name="cCdo_Documento"
                        onChange={handleChangeDocumento}
                        defaultValue=""
                      >
                      </input>
                    </div>

                    <div className="col-12">
                      <label className="col-md-12 col-form-label" style={{ textAlign: 'left', marginLeft: '6.4em' }}>
                        Fecha limite de entrega
                      </label>
                      <input
                        style={{ width: '300px', marginLeft: '6.4em' }}
                        className="form-control"
                        type="date"
                        value={getDocumentos.dCdo_Fech_Entrega}
                        name="dCdo_Fech_Entrega"
                        onChange={handleChangeDocumento}
                      />
                    </div>



                  </div>
                </div>
                <div className="modal-footer">
                  <div style={{ marginRight: 'auto' }}>
                    <input
                      type="checkbox"
                      name="checkbox"
                      value={chkbox}
                      checked={chkbox}
                      onChange={handleChangeDocumento}
                    >

                    </input >
                    <label>&nbsp;&nbsp; Enviar correo al cliente</label>
                  </div>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalBusquedaInsertar(1)}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => FuncionAgregarDocumento()}
                  >
                    Guardar <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </Modal>
              <Card className="cardBorder">
                <CardBody>
                  <div className="row" >
                    <div className="col-7"><i style={{ fontSize: "40px", float: "left" }} className="fas fa-briefcase">&nbsp;</i>
                      Caso<br />
                      <b style={{ fontSize: "13px" }}>{dataCasoInfo.map((row, i) => (
                        row.cCas_Titulo
                      ))}</b>
                    </div>
                    <div className="col-5">
                      {getDecryptedItem("RolID") != 9 && (
                        <button
                          onClick={handleClick}
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="fas fa-file-alt"></i>
                          &nbsp;
                          Solicitar Documentos
                        </button>

                      )}
                      {" "}
                      <button
                        onClick={() => abrirCerrarModalEliminado({ status: 1, data: {} })}
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        <i className="fas fa-trash"></i>
                        &nbsp;
                        Papelera
                      </button>{" "}
                    </div>
                    <label style={{ fontSize: "12px" }}>Código: <b>{dataCasoInfo.map((row, i) => (
                      row.cCas_Cod_Externo
                    ))}</b>{" // "}<b style={{ color: "#1F60AB" }}>
                        {dataCasoInfo.map((row, i) => (
                          row.cCas_Cod_Interno
                        ))}</b>
                      &nbsp;&nbsp;&nbsp;Cliente: <b style={{ color: "#AA1B0E" }}>{dataCasoInfo.map((row, i) => (
                        row.cCli_NombreCompleto
                      ))}</b></label>
                  </div>

                  <div className="combos-doc">
                    <div hidden={hiddenCliente}>
                      <label className="texto-combex">Cliente</label>
                      <select style={{ textAlignLast: "left" }} disabled={getDisableCliente}
                        className="form-select inputDown"
                        name="Client"
                        value={dataConsulta && dataConsulta.Client}
                        onChange={handleChangeBusqueda}
                      >
                        <option value="0">Seleccionar</option>
                        {dataCliente.map((e) =>
                          <option value={e.nCli_Id}>
                            {e.cCli_NombreCompleto}
                          </option>
                        )}
                      </select>
                    </div>
                    <div>
                      <label className="texto-combex">Caso</label>
                      <select style={{}}
                        className="form-select inputDown"
                        name="Case"
                        disabled={dataConsulta.Client == '0' ? true : null}
                        value={dataConsulta && dataConsulta.Case}
                        onChange={handleChangeBusquedaCaso}>
                        <option value="0">Seleccionar</option>
                        {dataCases.map((row, i) => (
                          <option value={row.nCas_Id}>
                            {row.cCas_Titulo}
                          </option>
                        ))}
                      </select>
                    </div>

                  </div>

                </CardBody>

              </Card>
            </Col>
          </Row>
          <Row style={{/* height: "680px" */}}>
            <Col className="col-4" style={{ /*height: "600px", */overflow: "auto" }}>
              <h2 className="titulo">Pendientes <span style={{ fontSize: '14px' }}>{"(" + dataPendiente.length + ")"}</span></h2>
              <div style={{ overflow: "auto" }}>
                {dataPendiente.map(function (d, idx) {
                  return (<div className="mb-1" style={{ padding: '20px', fontcolor: 'black', background: '#dddde8', borderRadius: '15px' }}>
                    <h5 style={{ fontWeight: '600', color: 'black' }}>{d.cCdo_Documento} </h5>
                    <b>Tipo de Documento: </b><label>{d.cCdo_Tab_Tipo_Doc}</label><br></br>
                    <b>Fecha/hora de solicitud: </b><label>{d.dCdo_Fecha_Solicito}</label><br></br>
                    <b style={{ textAlign: 'right' }}>Fecha limite: </b><label>{d.dCdo_Fech_Entrega1}</label><br></br>
                    <button type="button" className="btn btn-primary"
                      onClick={() =>
                        abrirCerrarModalArchivoInsertar({ status: 1, data: d })}
                    ><i className="fas fa-file-upload">&nbsp;</i>Subir archivo</button>
                  </div>
                  )
                })}
              </div>
            </Col>


            <Col className="col-4" style={{ /*height: "600px",*/ overflow: "auto" }}>
              <h2 className="titulo">Por aprobar <span style={{ fontSize: '14px' }}>{"(" + dataPorAprobar.length + ")"}</span></h2>
              <div style={{ overflow: "auto" }}>
                {dataPorAprobar.map(function (d, idx) {
                  return (<div className="mb-1" style={{ padding: '20px', fontcolor: 'black', background: '#dddde8', borderRadius: '15px' }}>
                    <h5 style={{ fontWeight: '600', color: 'black' }}>{d.cCdo_Documento} </h5>
                    <b>Archivo:</b> <label>&nbsp; <i className="fas fa-file"></i> {d.cCdo_Archivo1}</label><br></br>
                    <b>Tipo de Documento: </b> <label>{d.cCdo_Tab_Tipo_Doc}</label><br></br>
                    <b>Fecha/hora de carga: </b> <label>{d.dCdo_Fech_Entrega}</label><br></br>

                    {getDecryptedItem("RolID") != 9 && (
                      < button type="button" className="btn btn-success"
                        onClick={() =>
                          fAprobarDocumento(d)}
                      ><i className="far fa-check-square">&nbsp;</i>Aprobar</button>
                    )}
                    &nbsp;

                    {getDecryptedItem("RolID") != 9 && (
                      <Button color="danger"
                        onClick={() =>
                          abrirCerrarModalInsertarComentario(d)
                        }
                      >
                        <i className="fas fa-times-circle">&nbsp;</i>Rechazar</Button>
                    )}
                    &nbsp;
                    <button type="button" className="btn btn-primary"
                      onClick={() => VerDoc(d)}>
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          fontSize: "17px",
                          marginTop: "0",
                          cursor: "pointer",
                        }}
                        icon={faEye}
                        className=""
                      />
                      &nbsp;
                      Visualizar
                    </button>
                  </div>
                  )
                })}
              </div>
            </Col>



            <Col className="col-4" style={{ /*height: "600px", */overflow: "auto" }}>
              <h2 className="titulo">Aprobados <span style={{ fontSize: '14px' }}>{"(" + dataAprobado.length + ")"}</span></h2>
              <div style={{ overflow: "auto" }}>
                {dataAprobado.map(function (d, idx) {
                  return (<div className="mb-1" style={{ padding: '20px', fontcolor: 'black', background: '#dddde8', borderRadius: '15px' }}>
                    <h5 style={{ fontWeight: '600', color: 'black' }}>{d.cCdo_Documento} </h5>
                    <b>Archivo:</b> <label>&nbsp; <i className="fas fa-file"></i> {d.cCdo_Archivo1}</label><br></br>
                    <b>Tipo de Documento: </b> <label>{d.cCdo_Tab_Tipo_Doc}</label><br></br>
                    <b>Fecha/hora de aprobación:</b> <label> {d.dCdo_Fecha_Aprobo}</label><br></br>
                    <button type="button" className="btn btn-primary"
                      onClick={() => VerDoc(d)}>
                      <i className="fas fa-arrow-circle-down"></i>
                      &nbsp;
                      Descargar
                    </button>
                  </div>
                  )
                })}
              </div>
            </Col>


          </Row>
          <Row style={{ height: "680px" }}>
            <Col className="col-4" style={{ height: "600px", overflow: "auto" }}>
              <h2 className="titulo">Rechazados <span style={{ fontSize: '14px' }}>{"(" + dataRechazados.length + ")"}</span></h2>
              <div style={{ overflow: "auto" }}>
                {dataRechazados.map(function (d, idx) {
                  return (<div className="mb-1" style={{ padding: '20px', fontcolor: 'black', background: '#dddde8', borderRadius: '15px' }}>
                    <h5 style={{ fontWeight: '600', color: 'black' }}>{d.cCdo_Documento} </h5>
                    <b>Archivo:</b> <label>&nbsp; <i className="fas fa-file"></i> {d.cCdo_Archivo1}</label><br></br>
                    <b>Tipo de Documento: </b> <label>{d.cCdo_Tab_Tipo_Doc}</label><br></br>
                    <b>Fecha/hora de rechazo:</b> <label>{d.dCdo_Fecha_Rechazado}</label><br></br>
                    <b>Motivo de rechazo:</b> <label>{d.cCdo_comentario}</label><br></br>
                    <button type="button" className="btn btn-primary"
                      onClick={() => abrirCerrarModalArchivoInsertar({ status: 1, data: d })}
                    ><i className="fas fa-file-upload">&nbsp;</i>Subir archivo</button>
                    &nbsp;
                    {getDecryptedItem("RolID") != 9 && (
                      <Button color="danger"
                        onClick={() => fEliminarDocumento(d)}
                      ><i className="fas fa-trash"></i>&nbsp;Eliminar</Button>
                    )}
                  </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default NuevoModulos123


