import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom"
// Import Routes
import { ModuloRouter, LoginRouter } from "./routes/"
import AppRoute from "./routes/route"
// layouts
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
// Import scss
import "./assets/scss/theme.scss"
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_URL

const App = (props) => {
  const [isServiceDisabled, setIsServiceDisabled] = useState(false);
  const getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };
  const Layout = getLayout();
  const isServiceSuspended = async () => {
    const response = await axios.post(baseUrl + "/Suscripciones/VerificarEstado");
    if (response.status == 200) {
      let state = response.data[0].cSsi_Estado
      if (state !== 'ED01') {
        setIsServiceDisabled(true)
      }
    }
  }
  useEffect(() => {
    // isServiceSuspended()
  }, [])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {LoginRouter.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {!isServiceDisabled &&
            ModuloRouter.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
              />
            ))}
        </Switch>
        {isServiceDisabled && <Redirect to="/ServiceDisabled" />}
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.object,
};

// const mapStateToProps = (state) => {
//   return {
//     layout: state.Layout,
//   };
// };

// export default connect(mapStateToProps, null)(App);
// const mapStateToProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }
// App.propTypes = {
//   layout: PropTypes.object,
// }
export default App
