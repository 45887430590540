import PropTypes from "prop-types"
import React, { Component } from "react"
//Simple bar
import SimpleBar from "simplebar-react"
import "assets/Iconos/style.css"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import instance from '../../config'
import { getDecryptedItem } from '../../util';
//i18n
import { withTranslation } from "react-i18next"
const baseUrl = process.env.REACT_APP_API_URL

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
    this.state = {
      hiddenDashboard: true,
      hiddenCaso: true,
      hiddenCliente: true,
      hiddenEvento: true,
      hiddenTarea: true,
      hiddenIngresos: true,
      hiddenEgresos: true,
      hiddenDocumentos: true,
      hiddenHorasLaboradas: true,
      hiddenProcesarLiquidacion: true,
      hiddenReportes: true,
      hiddenEstadoCuenta: true,
      hiddenLiquidacion: true,
      hiddenAnalitico: true,
      hiddenCorreosEnviados: true,
      hiddenPersonalizar: true,
      hiddenRoles: true,
      hiddenUsuario: true,
      hiddenNotificacion: true,
      hiddenMaestros: true,
      hiddenCrearW: true,
      hiddenSuscriptores: true,
      hiddenFlujo: true,
      hiddenCalendario: true,
      hiddenCasosArchivados: true,
      hiddenLog: true,
      hiddenMiCuenta: true,
      hiddenTarifas: true,
      hiddenCuentas: true,
    };
  }
  FuncionListarEventos = async () => {
    var postData = {
      
      nUsu_ID: parseInt(getDecryptedItem('RolID'))
    }

    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {

        const UsuarioAcceso = response.data.map((row, i) => {
          if (row.cUsu_Modulo == "ME01") {
            this.setState({ hiddenDashboard: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME02") {
            this.setState({ hiddenCaso: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME03") {
            this.setState({ hiddenCliente: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME04") {
            this.setState({ hiddenEvento: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME05") {
            this.setState({ hiddenTarea: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME06") {
            this.setState({ hiddenIngresos: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME07") {
            this.setState({ hiddenEgresos: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME09") {
            this.setState({ hiddenDocumentos: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME10") {
            this.setState({ hiddenHorasLaboradas: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME11") {
            this.setState({ hiddenProcesarLiquidacion: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME12") {
            // this.setState({ hiddenReportes: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME13") {
            this.setState({ hiddenEstadoCuenta: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME14") {
            this.setState({ hiddenLiquidacion: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME15") {
            this.setState({ hiddenAnalitico: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME16") {
            this.setState({ hiddenCorreosEnviados: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME17") {
            // this.setState({ hiddenPersonalizar: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME19") {
            this.setState({ hiddenRoles: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME20") {
            this.setState({ hiddenUsuario: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME21") {
            this.setState({ hiddenNotificacion: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME22") {
            this.setState({ hiddenMaestros: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME23") {
            this.setState({ hiddenCrearW: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME24") {
            this.setState({ hiddenSuscriptores: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME25") {
            this.setState({ hiddenFlujo: !Boolean(parseInt(row.cUsu_Opcion)) })
          }else if (row.cUsu_Modulo == "ME08") {
            this.setState({ hiddenCalendario: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME27") {
            this.setState({ hiddenCasosArchivados: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME28") {
            this.setState({ hiddenLog: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME18") {
            this.setState({ hiddenMiCuenta: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME30") {
            this.setState({ hiddenTarifas: !Boolean(parseInt(row.cUsu_Opcion)) })
          } else if (row.cUsu_Modulo == "ME31") {
            this.setState({ hiddenCuentas: !Boolean(parseInt(row.cUsu_Opcion)) })
          }

          // Reportes validar si algun submenú esta habilitado
          if( row.cUsu_Modulo == "ME13" ||
              row.cUsu_Modulo == "ME15" || 
              row.cUsu_Modulo == "ME16" ||
              row.cUsu_Modulo == "ME27" ||
              row.cUsu_Modulo == "ME28"
             ){ //submenús de reportes
            if(Boolean(parseInt(row.cUsu_Opcion))){
              this.setState({ hiddenReportes: false }) // coloca en false el menú principal de reportes
            }
          }

          // Personalizar validar si algun submenú esta habilitado
          if( row.cUsu_Modulo == "ME18" ||
              row.cUsu_Modulo == "ME19" || 
              row.cUsu_Modulo == "ME20" ||
              row.cUsu_Modulo == "ME21" ||
              row.cUsu_Modulo == "ME22" ||
              row.cUsu_Modulo == "ME30" ||
              row.cUsu_Modulo == "ME31" 
            ){ //submenús de reportes
            if(Boolean(parseInt(row.cUsu_Opcion))){
              console.log('entro ')
              this.setState({ hiddenPersonalizar: false }) // coloca en false el menú principal de personalizar
            }
          }
        })
      })
      .catch(error => {

      })
    
  }
  componentDidMount() {
    this.initMenu()
    this.FuncionListarEventos()
  }
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }
  initMenu() {
    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }
  // componentDidUpdate() {}
  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }
  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li style={{ height: "35px" }} hidden={this.state.hiddenDashboard}>
                <Link to={getDecryptedItem('RolID') == '9' ? "/dashboard-resumen" : "/dashboard-Abogado"} className="waves-effect">
                  <i className="icon-Dashboard"></i>
                  <span className="NegritaMenu">{this.props.t("Dashboard")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenCaso}>
                <Link to="/Casos" className="waves-effect">
                  <i className="icon-Casos"></i>
                  <span className="NegritaMenu">{this.props.t("Casos")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenCliente}>
                <Link to="/Cliente" className="waves-effect">
                  <i style={{ fontSize: "1.30rem" }} className="icon-clientes"></i>
                  <span className="NegritaMenu">{this.props.t("Contactos")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenEvento}>
                <Link to="/Evento" className="waves-effect">
                  <i style={{ fontSize: "1.30rem" }} className="icon-eventos1"></i>
                  <span className="NegritaMenu">{this.props.t("Eventos")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenTarea}>
                <Link to="/Tareas" className="waves-effect">
                  <i className="icon-tarea"></i>
                  <span className="NegritaMenu">{this.props.t("Tareas")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenCalendario}>
                <Link to="/calendar" className="waves-effect">
                  <i className="icon-calendario"></i>
                  <span className="NegritaMenu">{this.props.t("Calendario")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenIngresos}>
                <Link to="/Ingresos" className="waves-effect">
                  <i style={{ fontSize: "1.30rem" }} className="icon-Ingresos-copy"></i>
                  <span className="NegritaMenu">{this.props.t("Ingresos")}</span>
                </Link>

              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenEgresos}>
                <Link to="/Egresos" className="waves-effect">
                  <i style={{ fontSize: "1.30rem" }} className="icon-Egresos"></i>
                  <span className="NegritaMenu">{this.props.t("Egresos")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenHorasLaboradas}>
                <Link to="/HorasLaboradas" className="waves-effect">
                  <i style={{ fontSize: "1.30rem" }} className="icon-Horas-Laboradas2"></i>
                  <span className="NegritaMenu">{this.props.t("Horas laboradas")}</span>
                </Link>
              </li>
              <li style={{ height: "35px" }} hidden={this.state.hiddenDocumentos}>
                <Link to="/Documentos" className="waves-effect">
                  <i className="icon-documentos-copy"></i>
                  <span className="NegritaMenu">{this.props.t("Documentos")}</span>
                </Link>
              </li>
              <li hidden={this.state.hiddenLiquidacion}>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="icon-proceso-de-liquidacion"></i>
                  <span className="NegritaMenu">{this.props.t("Liquidaciones")}</span>
                </Link>
                <ul className="sub-menu" >
                  <li style={{ height: "35px" }} hidden={this.state.hiddenProcesarLiquidacion}>
                    <span className="NegritaMenu"><Link to="/ReporteProcesar">{this.props.t("Procesar")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} >
                    <span className="NegritaMenu"><Link to="/ListadoReporteLiquidacion">{this.props.t("Listado")}</Link></span>
                  </li>
                </ul>
              </li>
              <li hidden={this.state.hiddenReportes}>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="icon-reportes"></i>
                  <span className="NegritaMenu">{this.props.t("Reportes")}</span>
                </Link>
                <ul className="sub-menu" >
                  <li style={{ height: "35px" }} hidden={this.state.hiddenEstadoCuenta}>
                    <span className="NegritaMenu"><Link to="/ReporteEstadoCuenta">{this.props.t("Estado de Cuenta")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenAnalitico}>
                    <span className="NegritaMenu"><Link to="/ReporteAvanzado">{this.props.t("Analítico")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenCorreosEnviados}>
                    <span className="NegritaMenu"><Link to="/Correo">{this.props.t("Correos Enviados")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenCasosArchivados}>
                    <span className="NegritaMenu"><Link to="/CasosDesactivados">{this.props.t("Casos Inactivos")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenLog}>
                    <span className="NegritaMenu"><Link to="/Incidencias">{this.props.t("Log")}</Link></span>
                  </li>
                </ul>
              </li>

              <li hidden={this.state.hiddenPersonalizar}>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="icon-configuracion"></i>
                  <span className="NegritaMenu">{this.props.t("Personalizar")}</span>
                </Link>
                <ul className="sub-menu" >
                  <li style={{ height: "35px" }} hidden={this.state.hiddenMiCuenta}>
                    <span className="NegritaMenu"><Link to="/MiCuenta">{this.props.t("Mi Cuenta")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenRoles} >
                    <span className="NegritaMenu"><Link to="/Roles">{this.props.t("Roles")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenUsuario}>
                    <span className="NegritaMenu"><Link to="/Usuario">{this.props.t("Usuarios")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenNotificacion}>
                    <span className="NegritaMenu"><Link to="/Notificaciones">{this.props.t("Notificaciones")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenMaestros}>
                    <span className="NegritaMenu"><Link to="/Maestros">{this.props.t("Maestros")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenTarifas}>
                    <span className="NegritaMenu"><Link to="/Tarifa">{this.props.t("Tarifas")}</Link></span>
                  </li>
                  <li style={{ height: "35px" }} hidden={this.state.hiddenCuentas}>
                    <span className="NegritaMenu"><Link to="/Cuenta">{this.props.t("Cuentas")}</Link></span>
                  </li>

                </ul>
              </li>
              <li hidden={this.state.hiddenFlujo}>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="icon-Flujo"></i>
                  <span className="NegritaMenu">{this.props.t("Flujos")}</span>
                </Link>
                <ul className="sub-menu" >
                  <li style={{ height: "35px" }}>
                    <span className="NegritaMenu"><Link to="/Flujo"
                      onClick={() => {
                        localStorage.removeItem('nfTar_Id');
                      }}
                    >  {this.props.t("Flujos")}</Link></span>
                  </li>

                </ul>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}
export default withRouter(withTranslation()(SidebarContent))


  /** Leyenda de modulos
      * 
      * 
      * Menú (Código)	             |  Submenús (Códigos)
      
        Dashboard (ME01)	-
        Casos (ME02)	-
        Contactos (ME03)	-
        Eventos (ME04)	-
        Tareas (ME05)	-
        Calendario (ME08)	-
        Ingresos (ME06)	-
        Egresos (ME07)	-
        Documentos (ME09)	-
        Horas Laboradas (ME10)	-
        Liquidaciones (ME14)	-       Procesar (ME11) - Listado (ME11) 
        Reportes (ME12)	-             Estado de Cuenta (ME13) - Analítico (ME15) - Correos Enviados (ME16) - Casos Inactivos (ME27) - Log (ME28)
        Personalizar (ME17)	-         Mi Cuenta (ME18) - Roles (ME19) - Usuarios (ME20) - Notificaciones (ME21) - Maestros (ME22) - Tarifas (ME30) - Cuentas (ME31)
        Flujos (ME25)	-               Flujos (ME25)
      */
